import { removeToken, setToken } from "@/utils/auth";
import { getUserInfo } from "@/api/user";
import { useLang } from "@/hooks/useLang";
const { lang } = useLang();
let userInfo;
try {
  userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
} catch (error) {
  localStorage.clear();
}

const user = {
  namespaced: true,
  state: {
    lang: localStorage.getItem("lang"),
    isLogin: localStorage.getItem("isLogin") || "0",
    userInfo: userInfo,
  },
  getters: {
    isLogin(state) {
      return state.isLogin;
    },
    lang(state) {
      return state.lang;
    },
  },
  mutations: {
    changeLogin(state, isLogin) {
      state.isLogin = isLogin;
      localStorage.setItem("isLogin", isLogin);
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
  },
  actions: {
    // 登录
    async logInSys(context, token) {
      return new Promise(async (resolve, reject) => {
        setToken(token);
        context.commit("changeLogin", "1");
        const { message } = await getUserInfo({ lang: lang.value });
        context.commit("setUserInfo", message);
        resolve();
      });
    },
    // 退出登录
    async logOut(context) {
      return new Promise((resolve, reject) => {
        context.commit("changeLogin", "0");
        removeToken();
        resolve();
      });
    },
    async getUserInfo(context) {
      return new Promise(async (resolve, reject) => {
        const { message } = await getUserInfo({ lang: lang.value });
        context.commit("setUserInfo", message);
        resolve();
      });
    },
  },
};

export default user;
