<template>
  <div class="header flex jus-bet">
    <div class="menuContainer flex">
      <img
        src="../../assets/images/logo.png"
        style="width: 40px; height: 40px; cursor: pointer"
        @click="jumpHome"
      />
      <el-menu
        :default-active="activeIndex"
        class="el-menu-content"
        mode="horizontal"
        @select="handleSelect"
      >
        <template v-for="item in menuList">
          <template v-if="item.children && item.children.length > 0">
            <el-submenu :index="item.path" :key="item.SECTION_ID">
              <template slot="title">{{ item.name }}</template>
              <el-menu-item
                v-for="sonItem in item.children"
                :key="sonItem.SECTION_ID"
                :index="sonItem.path"
                >{{ sonItem.name }}</el-menu-item
              >
            </el-submenu>
          </template>
          <template v-else>
            <el-menu-item :key="item.SECTION_ID" :index="item.path">{{
              item.name
            }}</el-menu-item>
          </template>
        </template>
      </el-menu>
    </div>
    <div class="headerTop ali-cen flex">
      <headerStatus></headerStatus>
    </div>
  </div>
</template>
<script setup>
import headerStatus from "./headerStatus.vue";
import useMenu from "@/hooks/useMenu";
import { onMounted, ref, watch } from "vue";
import { useRouter } from "@/utils/vueApi";
import { setToken } from "@/utils/auth";
import { useStore } from "@/utils/vueApi";
const store = useStore();

const { pageRoute } = useMenu();
const router = useRouter();
const activeIndex = ref(pageRoute.value.fullPath);
const { menuList, jumpRouter } = useMenu();
const handleSelect = (key, keyPath) => {
  jumpRouter(key);
};
// 测试登录
// setToken('21312312312312');
// store.commit({
//   type: "user/setUserInfo",
//   params: {a: 1},
// });
const jumpHome = () => {
  router.push("/");
};
watch(
  () => pageRoute,
  (val) => {
    activeIndex.value = val.value.fullPath;
  },
  { deep: true }
);
</script>
<style lang="scss" scope>
.header {
  width: 100%;
  background-color: $section-color;
  box-shadow: 0 2px 10px rgb(142 131 131 / 30%);
  padding: 10px;
  height: 55px;
  box-sizing: border-box;
  z-index: 9;
  .headerTop {
    display: flex;
  }

  .menuContainer {
    height: 60px;

    .el-menu-content {
      display: flex;
      vertical-align: middle;
      margin-left: 10px;

      .el-menu-item {
        padding: 0;
        &.is-active {
          border: none !important;
        }
      }
      .el-submenu__title {
        border-bottom: none !important;
      }
    }

    .el-menu.el-menu--horizontal {
      border-bottom: none;
      height: 40px;
      .el-menu-item {
        padding: 0 5px;
        &:hover {
          background-color: $hover-color;
        }
      }
      .el-submenu__title,
      .el-menu-item {
        height: 40px;
        line-height: 40px;
        color: $text-color;
        background-color: $section-color;
        font-size: 16px;
      }
    }
  }
}
</style>
