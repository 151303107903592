<template>
  <div class="loginBox">
    <div class="loginContent flex">
      <div class="imgBox">
        <img
          src="../assets/images/login.3acbfd93.svg"
          style="width: 500px; height: 500px"
          alt=""
        />
      </div>
      <div class="formBox">
        <h3>{{ $t("loginInfo.loginTitle") }}</h3>
        <p>{{ $t("loginInfo.loginTip") }}</p>
        <div class="formLine">
          <el-input
            :placeholder="$t('loginInfo.userNamePlc')"
            v-model="form.userName"
          >
            <i slot="prefix" class="el-input__icon el-icon-user"></i>
          </el-input>
        </div>
        <div class="formLine">
          <el-input
            :placeholder="$t('loginInfo.userPassPlc')"
            type="password"
            v-model="form.password"
          >
            <i slot="prefix" class="el-input__icon el-icon-unlock"></i>
          </el-input>
        </div>
        <div class="formLine">
          <el-button type="primary" @click="postLogin">{{
            $t("loginInfo.login")
          }}</el-button>
        </div>
        <div class="formLine botLine">
          <span class="forgetTip" @click="router.push('/register')">{{
            $t("loginInfo.register")
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, reactive, getCurrentInstance } from "vue";
import { Message, Loading } from "element-ui";
import { login } from "@/api/user";
import { useLang } from "@/hooks/useLang";
import { useRouter, useStore } from "@/utils/vueApi";
const router = useRouter();
const vm = getCurrentInstance();
const { lang } = useLang();
const store = useStore();
// const userStore = useUserStore();
const form = reactive({
  userName: "",
  password: "",
  code: "",
});
let loadingInstance;
// 登录
const postLogin = async () => {
  if (!form.userName) {
    Message.error(vm.proxy.$t("loginInfo.userNameTip"));
    return false;
  } else if (!form.password) {
    Message.error(vm.proxy.$t("loginInfo.userPassTip"));
    return false;
  }
  loadingInstance = Loading.service({
    lock: true,
    text: vm.proxy.$t("tips.loadingText"),
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
  const postData = {
    user_string: form.userName,
    password: form.password,
    area_code: 0,
    area_code_id: 0,
    country_code: "+86",
    lang: lang.value,
  };
  try {
    const { type, message } = await login(postData);
    if (type == "ok") {
      Message.success(vm.proxy.$t("loginInfo.loginSuccess"));
      await store.dispatch("user/logInSys", message);
      loadingInstance.close();
      router.push("/");
    }
  } catch (error) {
    loadingInstance.close();
  }
};
</script>
<style lang="scss" scope>
.loginBox {
  width: 100%;
  height: 100%;
  padding: 0 10%;
  box-sizing: border-box;
  .loginContent {
    height: 100%;
    justify-content: space-around;
    align-items: center;
    h3 {
      font-weight: 600;
      font-size: 32px;
    }
    p {
      color: #737477;
    }
  }
  .formBox {
    width: 380px;
    .formLine {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      .el-button {
        background-color: #5670f6;
        color: #fff;
        font-weight: bold;
        display: block;
        width: 100%;
        border: none;
        opacity: 0.65;
      }
      .forgetTip {
        color: #5670f6;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
</style>
