// 韩国
export default {
  loginInfo: {
    loginTitle: "계정 로그인",
    registerTitle: "계정 등록",
    loginTip:
      "다시 오신 것을 환영합니다! 이메일이나 휴대폰 번호로 로그인해주세요.",
    registerTip: "이메일이나 휴대폰 번호로 등록해주세요.",
    userNamePlc: "계정을 입력하세요",
    userPassPlc: "비밀번호를 입력하세요",
    login: "로그인",
    register: "등록",
    overtime: "로그인 세션이 만료되었습니다. 다시 로그인해주세요.",
    loginSuccess: "로그인 성공",
    registerSuccess: "등록 성공",
    userNameTip: "계정을 입력하세요",
    userPassTip: "비밀번호를 입력하세요",
    userOldPassTip: "기존 비밀번호를 입력하세요",
    userNewPassTip: "새 비밀번호를 입력하세요",
    userPassAgainTip: "새 비밀번호를 다시 입력하세요",
    errorPassTip: "두 비밀번호가 일치하지 않습니다",
    userPassSureTip: "비밀번호를 다시 입력하세요",
    jumpPageText: "현재 페이지는 로그인이 필요합니다. 로그인해주세요.",
  },
  tips: {
    loginoutSuccess: "로그아웃 성공",
    tip: "알림",
    success: "성공",
    error: "실패",
    pleaseSelect: "선택해주세요",
    pleaseEnter: "입력해주세요",
    pleaseUpload: "업로드해주세요",
    cancelText: "취소",
    sureText: "확인",
    submitText: "제출",
    uploadImg: "올바른 이미지 형식을 업로드해주세요!",
    uploadImgSize: "5MB 미만의 이미지를 업로드해주세요!",
    inputCode: "인증 코드 입력",
    inputNewEmail: "새 이메일 입력",
    inputSureEmail: "올바른 형식의 이메일을 입력해주세요",
    getCodeText: "인증 코드 받기",
    editText: "수정",
    viewText: "보기",
    reGetCodeText: "재발송",
    editPassTip: "로그인 비밀번호 변경",
    editPayTip: "결제 비밀번호 변경",
    loadingText: "로딩 중",
    addText: "추가",
    sureRelieveText: "정말 해제하시겠습니까?",
    sureClosing: "정말 청산하시겠습니까?",
    pleaseRechargeChoose: "충전 채널을 선택해주세요",
    pleaseRechargeInput: "충전 금액을 입력해주세요",
  },
  userText: {
    primaryCertification: "초급 인증",
    advancedCertification: "고급 인증",
    hasAuth: "인증 완료",
    noAuth: "인증되지 않음",
    auditIng: "심사 중",
    userInfo: "개인 정보",
    idCardInfo: "신분증 정보",
    cardInfo: "증명서 정보",
    startBegin: "즉시 시작",
    authTip: "초급 인증 후 고급 인증 가능",
    userName: "이름",
    sureName: "성",
    cardNumber: "증명서 번호",
    cardType: "증명서 유형",
    cityName: "국가/지역",
    addressName: "주소",
    idCardType: "신분증",
    passport: "여권",
    drivingLicense: "운전 면허증",
    idCardFront: "신분증 앞면",
    idCardBack: "신분증 뒷면",
    payPassText: "결제 비밀번호",
    loginPassText: "로그인 비밀번호",
    bindEmailText: "이메일 바인딩",
    dataStatistics: "데이터 통계",
    transactionVarieties: "거래 내역",
    accuracy: "정확도",
    currentPosition: "현재 보유량",
    totalOrder: "총 주문 수",
    profitOrder: "수익 주문",
    lossOrder: "손실 주문",
    successOrder: "성공적인 매수 주문",
    successShortOrder: "성공적인 매도 주문",
    yesterdayTransaction: "어제의 거래 상태",
    profitText: "수익",
    historyFollow: "역사적 팔로워 수",
    fictitiousContractName: "가상화폐 초 단위 계약",
    orderRatio: "주문 비율",
    buyNumber: "매수 횟수",
    sellNumber: "매도 횟수",
    metalsSecondContract: "외환/귀금속 초 단위 계약",
    virtualCoinContract: "가상화폐 계약",
    preciousMetalContracts: "외환/귀금속 계약",
    bindDigitalAddress: "디지털 주소 바인딩",
    digitalAddress: "디지털 주소",
    bindText: "바인딩",
    relieveText: "해제",
    addBankText: "디지털 지갑 추가",
    bankAddress: "지갑 주소",
    rechargeCurrency: "충전 코인",
    bankRecharge: "은행 카드 충전",
    manualService: "수동 충전 서비스",
  },
  // 상단 네비게이션
  headerText: {
    recharge: "빠른 충전",
    memberCenter: "회원 센터",
    switchAccounts: "계정 전환",
    bankCard: "은행 카드 관리",
    investmentStatistics: "투자 통계",
    logOut: "로그아웃",
    helpCenter: "도움말 센터",
    aboutSelf: "회사 소개",
    startBegin: "신규 사용자 가이드",
    scanText: "이 QR 코드를 스캔해주세요",
    downText: "다운로드",
    liaison: "연락처",
    customerService: "고객 서비스",
    login: "로그인/등록",
    accountSet: "계정 설정",
  },
  // 메뉴
  menu: {
    quotation: "시세",
    transactionType: "거래 종류",
    transaction: "거래",
    currencytransaction: "코인-코인 거래",
    fiatTransaction: "법정통화 거래",
    contract: "계약",
    assets: "자산",
    secondContract: "초 단위 계약",
  },
  // 순위
  rankText: {
    priceIncrease: "가격 상승률 순위",
    transactionAmount: "거래량 순위",
    name: "이름",
    nowPrice: "최신 가격",
    transactionType: "거래 종류",
    amount: "수량",
    operation: "작업",
    futures: "선물",
    priceRate: "가격 변동률",
    emptyText: "데이터 없음",
  },
  // 홈 하단
  homeFooter: {
    joinUs:
      "NYSEIMF에 오신 것을 환영합니다. 함께 NYSEIMF의 미래를 만들어갑시다.",
  },
  // 시세
  market: {
    typeName: "종류",
    digitalCurrency: "디지털 화폐",
    nobleMetal: "귀금속",
    internationalFutures: "국제 선물",
    currency: "화폐",
    foreignExchange: "외환",
    marketText: "시장",
    productName: "제품",
    dealPrice: "체결 가격",
    direction: "방향",
    time: "시간",
    profitLoss: "손익",
    beginPrice: "시작 가격",
    highestPrice: "최고 가격",
    lowestPrice: "최저 가격",
    turnover: "거래량",
    closePrice: "마감 가격",
    buyMore: "매수(롱)",
    buyEmpty: "매도(숏)",
    doMore: "롱 포지션",
    doLess: "숏 포지션",
    hasNum: "보유량",
    tip: "만료 시간 선택 (더 높은 수익률 위해 왼쪽으로 슬라이드)",
    second: "초",
    numberInput: "투자 금액",
    numberInputPlac: "투자 금액을 입력하세요",
    canUse: "사용 가능",
    balance: "잔액",
    serviceCharge: "수수료",
    commission: "시장가 주문",
    commissionText: "시장가",
    fixedPrice: "지정가 주문",
    fixedText: "지정가",
    commissionPlac: "주문 금액을 입력하세요",
    area: "범위",
    price: "가격",
    volume: "수량",
    volumePlac: "수량을 입력하세요",
    coinCurrency: "코인-코인",
    currentCommission: "현재 주문",
    historyCommission: "역사적 주문",
    introduction: "소개",
    wholeAll: "전체",
    transactionRecords: "거래 기록",
    tradingPairs: "거래 쌍",
    aTurnover: "거래량",
    newPrice: "최신 가격",
    orderNum: "주문 번호",
    status: "상태",
    entrustmentTime: "주문 시간",
    openHouse: "포지션 오픈",
    openHousePrice: "오픈 가격",
    nowText: "현재",
    netWorth: "순자산 가치",
    riskLevel: "위험도",
    takeProfit: "타겟 수익",
    profitPlace: "타겟 수익 금액을 입력하세요",
    stopLoss: "스톱 로스",
    lossPlace: "스톱 로스 금액을 입력하세요",
    bondMoney: "담보 자산",
    fontMoney: "1 단위 가격",
    font: "단위",
    buyMoreBtn: "매수(롱) (상승 예측)",
    buyLessBtn: "매도(숏) (하락 예측)",
    recharge: "충전",
    rechargeTips: "충전 내역",
    withdraw: "출금",
    floatingProfit: "부동 손익(실시간)",
    accountBalance: "계정 잔액",
    accountChoose: "계정 선택",
    accountChoosePlac: "계정을 선택하세요",
    freeMargin: "사용 가능한 담보",
    usedMargin: "사용된 담보",
    accountNet: "계정 순자산 가치",
    freezeAmount: "동결 금액",
    coinAccount: "코인 계정",
    contractAccount: "계약 계정",
    freezeText: "동결",
    disassemble: "USD로 환산",
    transactionPrice: "거래 가격",
    openingNum: "오픈 수량",
    guaranteePrice: "담보 자산",
    nowPrice: "현재 가격",
    takeProfitPrice: "타겟 수익 가격",
    stopLossPrice: "스톱 로스 가격",
    hangingPrice: "매수/매도 가격",
    historyOrders: "거래 역사 주문",
    typeCurrency: "종류/화폐",
    buyMoney: "매수 금액",
    sellPrice: "매도 가격",
    buyPrice: "구매 가격",
    profit: "수익",
    buyTime: "매수 시간",
    finshTime: "완료 시간",
    sellTime: "매도 시간",
    chainName: "체인 이름",
    chainChoosePlac: "코인을 선택하세요",
    chainNumPlac: "수량을 입력하세요",
    xxpectedRechargeTitle: "예상 USDT 충전 금액",
    uploadImgText: "이미지 업로드",
    markPaid: "지불 표시",
    payMoney: "지불 금액",
    payTime: "지불 시간",
    payStatus: "지불 상태",
    withdrawalDetails: "출금 내역",
    remark: "비고",
    rechargeAmount: "충전 금액",
    withdrawalAmount: "출금 금액",
    withdrawalAmountPlac: "출금 금액을 입력하세요",
    withdrawalTips: "최소 출금 금액은",
    nowNumberError: "현재 출금 금액이 최소 출금 금액보다 작습니다",
    submitBtnText: "제출 확인",
    orderNumber: "주문 수량",
  },
  order: {
    buyIng: "거래 중",
    closing: "청산 중",
    closingText: "청산",
    hasClosed: "청산 완료",
    waitPay: "지불 대기",
    rechargeFail: "충전 실패",
    rechargeSuccess: "충전 성공",
    callbacking: "콜백 중",
  },
  请先完成实名认证: "먼저 실명 인증을 완료해주세요",
  参数错误: "파라미터 오류",
  数据未找到: "데이터를 찾을 수 없습니다",
  还未创建账户: "아직 계정을 생성하지 않았습니다",
  "钱包未找到,请先添加钱包":
    "지갑을 찾을 수 없습니다. 먼저 지갑을 추가해주세요",
  资金钱包余额不足: "자금 지갑 잔액이 부족합니다",
  入金成功: "입금 성공",
  项目不存在: "프로젝트가 존재하지 않습니다",
  "最少存入数量为：": "최소 입금 수량은 다음과 같습니다:",
  success: "성공",
  数量不可低于0: "수량은 0 미만일 수 없습니다",
  账户余额不足: "계정 잔액이 부족합니다",
  你的冻结余额为0: "당신의 동결 잔액은 0입니다",
  存币成功: "코인 입금 성공",
  找不到存单: "입금 내역을 찾을 수 없습니다",
  非法操作: "불법적인 조작",
  存单状态异常: "입금 내역 상태가 이상합니다",
  毁约成功: "약속 파기 성공",
  功能暂不可用: "기능이 현재 사용 불가능합니다",
  订单不能取消: "주문 취소 불가능",
  提取数量超过持有数量: "보유량을 초과하여 출금할 수 없습니다",
  找不到交易对: "거래 쌍을 찾을 수 없습니다",
  帐户已被冻结: "계정이 동결되었습니다",
  请求异常: "요청 오류",
  状态异常: "상태가 이상합니다",
  "取消失败:": "취소 실패:",
  取消成功: "취소 성공",
  pid不能为空: "pid는 비워둘 수 없습니다",
  产品不存在: "제품이 존재하지 않습니다",
  配置错误: "설정 오류",
  金额错误: "금액 오류",
  项目状态错误: "프로젝트 상태 오류",
  暂无数据: "현재 데이터가 없습니다",
  文件大小超出: "파일 크기가 너무 큽니다",
  该文件已存在: "이 파일은 이미 존재합니다",
  文件类型不对: "파일 형식이 올바르지 않습니다",
  上传失败: "업로드 실패",
  订单取消成功: "주문 취소 성공",
  配置不存在: "설정이 존재하지 않습니다",
  您尚未激活此交易对的交易功能:
    "해당 거래 쌍의 거래 기능을 아직 활성화하지 않았습니다",
  "您的交易当前正在关闭，此时无法继续交易":
    "현재 거래가 종료 중입니다. 이 시점에서는 거래를 계속할 수 없습니다",
  类型错误: "타입 오류",
  此功能当前未打开: "이 기능은 현재 사용 불가능합니다",
  限价交易不能以低于当前价格的价格出售:
    "한도 주문은 현재 가격보다 낮은 가격으로 판매할 수 없습니다",
  提交成功: "제출 성공",
  此功能系统未打开: "이 기능은 시스템에서 사용 불가능합니다",
  止盈止损价格不能为0: "익절 및 손절가는 0일 수 없습니다",
  找不到交易记录: "거래 기록을 찾을 수 없습니다",
  "买入（多头）止盈价格不能低于开盘价和当前价格":
    "매수(롱) 익절가는 시가와 현재 가격보다 낮을 수 없습니다",
  "买入（做多）止损价格不能高于开盘价和当前价格":
    "매수(롱) 손절가는 시가와 현재 가격보다 높을 수 없습니다",
  "卖出（卖空）止盈价格不能高于开盘价和当前价格":
    "매도(숏) 익절가는 시가와 현재 가격보다 높을 수 없습니다",
  "卖出（卖空）止损价格不能低于开盘价和当前价格":
    "매도(숏) 손절가는 시가와 현재 가격보다 낮을 수 없습니다",
  设置成功: "설정 성공",
  设置失败: "설정 실패",
  购买方向传输错误: "구매 방향 전송 오류",
  取消成功: "취소 성공",
  "取消失败：": "취소 실패:",
  "取消失败：用户钱包不存在": "취소 실패: 사용자 지갑이 존재하지 않습니다",
  "交易记录不存在或已被取消。请刷新并重试":
    "거래 기록이 존재하지 않거나 이미 취소되었습니다. 새로고침 후 다시 시도해주세요",
  "找不到钱包，请先添加钱包":
    "지갑을 찾을 수 없습니다. 먼저 지갑을 추가해주세요",
  "余额不足，不能小于":
    "잔액이 부족합니다, 다음과 같거나 더 큰 금액이 필요합니다",
  "扣款失败：": "차감 실패:",
  "手续费扣减失败：": "수수료 차감 실패:",
  未找到数据: "데이터를 찾을 수 없습니다",
  未经授权的操作: "허가되지 않은 작업",
  "交易状态异常，请不要重新提交":
    "거래 상태가 비정상적입니다. 다시 제출하지 마세요",
  "关闭失败，请重试": "닫기에 실패했습니다. 다시 시도해주세요",
  "交易异常，无法平仓": "거래에 문제가 있어 청산할 수 없습니다",
  请输入账号: "계정을 입력하세요",
  请输入密码: "비밀번호를 입력하세요",
  用户不存在: "사용자가 존재하지 않습니다",
  密码错误: "비밀번호가 틀렸습니다",
  "您的帐户已被冻结。详情请咨询客服。":
    "귀하의 계정이 동결되었습니다. 자세한 내용은 고객 지원에 문의하세요.",
  密码只能在6到16位之间: "비밀번호는 6~16자리 사이여야 합니다",
  验证码错误: "인증 코드가 틀렸습니다",
  用户名已存在: "사용자 이름이 이미 존재합니다",
  请填写正确的邀请码: "올바른 초대 코드를 입력해주세요",
};
