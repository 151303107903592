import { useRouter, useStore, useRoute } from "@/utils/vueApi";
import { ref, watch, getCurrentInstance, computed } from "vue";
import { useUser } from "@/hooks/useUser";
import _ from "lodash";
// 监听页面变化，获取最新的route
export default function useMenu() {
  const router = useRouter();
  const store = useStore();
  const route = useRoute();
  const vm = getCurrentInstance();
  const { isLogin } = useUser();
  const menuList = computed(() => {
    if (isLogin.value) {
      return [
        {
          get name() {
            return vm.proxy.$t("menu.quotation");
          },
          path: "/market",
        },
        {
          name: vm.proxy.$t("menu.transaction"),
          path: "/marketDetail",
        },
        // {
        //   name: vm.proxy.$t("menu.transactionType"),
        //   path: "/marketDetail",
        //   children: [
        //     {
        //       name: vm.proxy.$t("menu.transaction"),
        //       path: "/marketDetail",
        //     },
        //     {
        //       name: vm.proxy.$t("menu.currencytransaction"),
        //       path: "/marketCurrency",
        //     },
        //     {
        //       name: vm.proxy.$t("menu.fiatTransaction"),
        //       path: "/marketCurrency",
        //     },
        //   ],
        // },
        {
          name: vm.proxy.$t("menu.contract"),
          path: "/marketContract",
        },
        {
          name: vm.proxy.$t("menu.assets"),
          path: "/assetsPage",
        },
      ];
    } else {
      return [
        {
          name: vm.proxy.$t("menu.quotation"),
          path: "/market",
        },
        {
          name: vm.proxy.$t("menu.contract"),
          path: "/marketContract",
        },
        {
          name: vm.proxy.$t("menu.assets"),
          path: "/assetsPage",
        },
      ];
    }
  });
  const jumpPage = (path, list) => {
    let temIndex = 0;
    if (list.children && list.children.length > 0) {
      list.children.forEach((e, index) => {
        if (e.path == path) {
          temIndex = index;
        }
      });
      const temE = list.children[temIndex].children;
      if (temE && temE.length > 0) {
        router.push(temE[0].path);
      } else {
        router.push(path);
      }
    } else {
      router.push(path);
    }
  };
  // 获取左侧菜单
  // const getLeftMenu = (arr, path) => {
  //   let menuIndex = [];
  //   function getTemMenu(sonArr, path, temIndex) {
  //     for (let i = 0; i < sonArr.length; i++) {
  //       const e = sonArr[i];
  //       if (e.path == path) {
  //         // debugger
  //         menuIndex.push(temIndex)
  //       } else if (e.children && e.children.length > 0) {
  //         getTemMenu(e.children, path, i);
  //       }
  //     }
  //   }
  //   getTemMenu(arr, path);
  //   console.log(menuIndex)
  //   return menuList.value[menuIndex[0]];
  // };
  // 获取左侧菜单
  const getLeftMenu = (arr, path) => {
    let findChildren = undefined;
    let cacheItem = undefined;
    const findPath = (children) => {
      return children.some((item) => {
        if (item.path === path) {
          findChildren = cacheItem;
          return true;
        } else {
          if (item.children) {
            return findPath(item.children);
          }
        }
      });
    };
    arr.some((item) => {
      cacheItem = item;
      return item.children && findPath(item.children);
    });
    return findChildren;
  };
  const jumpRouter = (path) => {
    const list = getLeftMenu(menuList.value, path);
    if (list) {
      jumpPage(path, list);
    } else {
      router.push(path);
    }
    // store.commit({
    //   type: "page/setInnerNav",
    //   params: list,
    // });
  };
  let pageRoute = ref(_.clone(route));
  // watch(
  //   () => vm.proxy.$route,
  //   (to, from) => {
  //     pageRoute.value = _.clone(to);
  //     // console.log(menuList.value)
  //     let list = getLeftMenu(menuList.value, pageRoute.value.fullPath);
  //     // console.log(menuList.value, pageRoute.value.fullPath)
  //     // console.log(list)
  //     list = list ? list : {};
  //     store.commit({
  //       type: "page/setInnerNav",
  //       params: list,
  //     });
  //   },
  //   { deep: true }
  // );
  return {
    menuList,
    jumpRouter,
    pageRoute,
  };
}
