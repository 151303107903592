

let innerNav;
try {
    innerNav = JSON.parse(localStorage.getItem("innerNav") || "[]");
  
} catch (error) {
  localStorage.clear();
}

const page = {
  namespaced: true,
  state: {
    innerNav: innerNav,
  },
  mutations: {
    setInnerNav(state, data) {
      state.innerNav = data.params;
      localStorage.setItem("innerNav", JSON.stringify(data.params));
    },
  },
};

export default page;
