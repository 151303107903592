// 泰国
export default {
  loginInfo: {
    loginTitle: "เข้าสู่ระบบ",
    registerTitle: "สมัครสมาชิก",
    loginTip: "ยินดีต้อนรับกลับมา! ใช้อีเมลหรือหมายเลขโทรศัพท์เข้าสู่ระบบ",
    registerTip: "ใช้อีเมลหรือหมายเลขโทรศัพท์สมัครสมาชิก",
    userNamePlc: "กรุณาใส่ชื่อผู้ใช้",
    userPassPlc: "กรุณาใส่รหัสผ่าน",
    login: "เข้าสู่ระบบ",
    register: "สมัครสมาชิก",
    overtime: "เวลาเข้าสู่ระบบหมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง",
    loginSuccess: "เข้าสู่ระบบสำเร็จ",
    registerSuccess: "สมัครสมาชิกสำเร็จ",
    userNameTip: "กรุณาใส่ชื่อผู้ใช้",
    userPassTip: "กรุณาใส่รหัสผ่าน",
    userOldPassTip: "กรุณาใส่รหัสผ่านเดิม",
    userNewPassTip: "กรุณาใส่รหัสผ่านใหม่",
    userPassAgainTip: "กรุณาใส่รหัสผ่านใหม่อีกครั้ง",
    errorPassTip: "รหัสผ่านไม่ตรงกัน",
    userPassSureTip: "กรุณาใส่รหัสผ่านอีกครั้ง",
    jumpPageText: "หน้านี้ต้องการการเข้าสู่ระบบเพื่อดู กรุณาเข้าสู่ระบบ",
  },
  tips: {
    loginoutSuccess: "ออกจากระบบสำเร็จ",
    tip: "แจ้งเตือน",
    success: "สำเร็จ",
    error: "ผิดพลาด",
    pleaseSelect: "กรุณาเลือก",
    pleaseEnter: "กรุณาใส่",
    pleaseUpload: "กรุณาอัปโหลด",
    cancelText: "ยกเลิก",
    sureText: "ยืนยัน",
    submitText: "ส่ง",
    uploadImg: "กรุณาอัปโหลดรูปภาพในรูปแบบที่ถูกต้อง!",
    uploadImgSize: "กรุณาอัปโหลดรูปภาพที่มีขนาดไม่เกิน 5M!",
    inputCode: "กรุณาใส่รหัสยืนยัน",
    inputNewEmail: "กรุณาใส่อีเมลใหม่",
    inputSureEmail: "กรุณาใส่อีเมลในรูปแบบที่ถูกต้อง",
    getCodeText: "รับรหัสยืนยัน",
    editText: "แก้ไข",
    viewText: "ดู",
    reGetCodeText: "หลังจากนี้ กรุณารับรหัสยืนยันใหม่อีกครั้ง",
    editPassTip: "แก้ไขรหัสผ่านเข้าสู่ระบบ",
    editPayTip: "แก้ไขรหัสผ่านการชำระเงิน",
    loadingText: "กำลังโหลด",
    addText: "เพิ่ม",
    sureRelieveText: "คุณแน่ใจหรือไม่ที่จะแยกตัว?",
    sureClosing: "คุณแน่ใจหรือไม่ที่จะปิดทำการ?",
    pleaseRechargeChoose: "กรุณาเลือกช่องเติมเงิน",
    pleaseRechargeInput: "กรุณาใส่จำนวนเงินที่ต้องการเติม",
  },
  userText: {
    primaryCertification: "การรับรองระดับเริ่มต้น",
    advancedCertification: "การรับรองระดับสูง",
    hasAuth: "มีการรับรอง",
    noAuth: "ไม่มีการรับรอง",
    auditIng: "กำลังตรวจสอบ",
    userInfo: "ข้อมูลส่วนตัว",
    idCardInfo: "ข้อมูลบัตรประชาชน",
    cardInfo: "ข้อมูลบัตร",
    startBegin: "เริ่มต้น",
    authTip: "หลังจากการรับรองระดับเริ่มต้น คุณสามารถรับรองระดับสูงได้",
    userName: "ชื่อ",
    sureName: "นามสกุล",
    cardNumber: "หมายเลขบัตร",
    cardType: "ประเภทบัตร",
    cityName: "ประเทศ/ภูมิภาค",
    addressName: "ที่อยู่",
    idCardType: "บัตรประชาชน",
    passport: "พาสปอร์ต",
    drivingLicense: "ใบขับขี่",
    idCardFront: "ฝั่งหน้าบัตรประชาชน",
    idCardBack: "ฝั่งหลังบัตรประชาชน",
    payPassText: "รหัสผ่านการชำระเงิน",
    loginPassText: "รหัสผ่านเข้าสู่ระบบ",
    bindEmailText: "การเชื่อมต่ออีเมล",
    dataStatistics: "สถิติข้อมูล",
    transactionVarieties: "พวกการทำธุรกรรมในอดีต",
    accuracy: "ความถูกต้อง",
    currentPosition: "ตำแหน่งปัจจุบัน",
    totalOrder: "คำสั่งซื้อทั้งหมด",
    profitOrder: "คำสั่งซื้อที่กำไร",
    lossOrder: "คำสั่งซื้อที่ขาดทุน",
    successOrder: "คำสั่งซื้อที่ทำการซื้อเพิ่มเติมสำเร็จ",
    successShortOrder: "คำสั่งซื้อที่ทำการขายเพิ่มเติมสำเร็จ",
    yesterdayTransaction: "สถานะการทำธุรกรรมเมื่อวานนี้",
    profitText: "กำไร",
    historyFollow: "จำนวนผู้ติดตามในอดีต",
    fictitiousContractName: "สัญญาสำหรับสินทรัพย์ดิจิทัล",
    orderRatio: "อัตราคำสั่งซื้อ",
    buyNumber: "จำนวนคำสั่งซื้อที่ซื้อ",
    sellNumber: "จำนวนคำสั่งซื้อที่ขาย",
    metalsSecondContract:
      "สัญญาสำหรับเหรียญทองและสินทรัพย์ที่เปิดออกในต่างประเทศ",
    virtualCoinContract: "สัญญาสำหรับสินทรัพย์ดิจิทัล",
    preciousMetalContracts:
      "สัญญาสำหรับเหรียญทองและสินทรัพย์ที่เปิดออกในต่างประเทศ",
    bindDigitalAddress: "การเชื่อมต่อที่อยู่ดิจิทัล",
    digitalAddress: "ที่อยู่ดิจิทัล",
    bindText: "เชื่อมต่อ",
    relieveText: "แยกตัว",
    addBankText: "เพิ่มสมุดเงินฝาก",
    bankAddress: "ที่อยู่สมุดเงินฝาก",
    rechargeCurrency: "สกุลเงินที่ต้องการเติมเงิน",
    bankRecharge: "เติมเงินบัตรเครดิต/เดบิต",
    manualService: "บริการเติมเงินด้วยตนเอง",
  },
  // ฝั่งบนของเมนู
  headerText: {
    recharge: "เติมเงิน",
    // กลุ่มส่วนตัว
    memberCenter: "กลุ่มส่วนตัว",
    switchAccounts: "สลับบัญชี",
    bankCard: "สมุดเงินฝาก",
    investmentStatistics: "สถิติการลงทุน",
    logOut: "ออกจากระบบ",
    // เมนูฝั่งขวาบน
    helpCenter: "ศูนย์ช่วยเหลือ",
    aboutSelf: "เกี่ยวกับเรา",
    startBegin: "เริ่มต้น",
    // แสกนรหัส QR
    scanText: "กรุณาสแกนรหัส QR นี้",
    // ดาวน์โหลด
    downText: "ดาวน์โหลด",
    liaison: "ติดต่อ",
    customerService: "บริการลูกค้า",
    login: "เข้าสู่ระบบ/สมัครสมาชิก",
    accountSet: "การตั้งค่าบัญชี",
  },
  // เมนู
  menu: {
    quotation: "ราคา",
    transactionType: "ประเภทการทำธุรกรรม",
    transaction: "การทำธุรกรรม",
    currencytransaction: "การทำธุรกรรมเหรียญ",
    fiatTransaction: "การทำธุรกรรมเงินทอน",
    contract: "สัญญา",
    assets: "ทรัพย์สิน",
    secondContract: "สัญญาสำหรับสินทรัพย์ที่เปิดออกในต่างประเทศ",
  },
  // อันดับ
  rankText: {
    priceIncrease: "อันดับการเพิ่มราคา",
    transactionAmount: "อันดับจำนวนการทำธุรกรรม",
    name: "ชื่อ",
    nowPrice: "ราคาล่าสุด",
    transactionType: "ประเภทการทำธุรกรรม",
    amount: "จำนวน",
    operation: "การดำเนินการ",
    futures: "สัญญานาน",
    priceRate: "อัตราการเพิ่มราคา",
    emptyText: "ไม่มีข้อมูล",
  },
  // ด้านล่างของหน้าแรก
  homeFooter: {
    joinUs:
      "ยินดีต้อนรับสู่ NYSEIMF ร่วมกับเราเพื่อสร้างแผน NYSEIMF ร่วมกับเราเพื่อสร้าง NYSEIMF ที่ดีที่สุด",
  },
  // ราคา
  market: {
    typeName: "ชนิด",
    digitalCurrency: "เหรียญดิจิทัล",
    nobleMetal: "เหรียญทอง",
    internationalFutures: "สัญญานานในต่างประเทศ",
    currency: "สกุลเงิน",
    foreignExchange: "การเปลี่ยนเงินตรา",
    marketText: "ตลาด",
    productName: "สินค้า",
    dealPrice: "ราคาที่ซื้อขาย",
    direction: "ทิศทาง",
    time: "เวลา",
    profitLoss: "กำไร/ขาดทุน",
    beginPrice: "ราคาเปิด",
    highestPrice: "ราคาสูงสุด",
    lowestPrice: "ราคาต่ำสุด",
    turnover: "จำนวนที่ซื้อขาย",
    closePrice: "ราคาปิด",
    buyMore: "ซื้อเพิ่มเติม",
    buyEmpty: "ขายเพิ่มเติม",
    doMore: "ซื้อเพิ่มเติม",
    doLess: "ขายเพิ่มเติม",
    hasNum: "จำนวนที่มี",
    tip: "เลือกเวลาหมดอายุ (เลื่อนซ้ายเพื่อเพิ่มกำไร)",
    second: "วินาที",
    numberInput: "จำนวนเงินที่ลงทุน",
    numberInputPlac: "กรุณาใส่จำนวนเงินที่ลงทุน",
    canUse: "สามารถใช้ได้",
    balance: "ยอดเงินคงเหลือ",
    serviceCharge: "ค่าธรรมเนียม",
    commission: "การสั่งซื้อที่ราคาเริ่มต้น",
    commissionText: "ราคาเริ่มต้น",
    fixedPrice: "การสั่งซื้อที่ราคาตั้งต้น",
    fixedText: "ราคาตั้งต้น",
    commissionPlac: "กรุณาใส่จำนวนเงินที่สั่งซื้อ",
    area: "พื้นที่",
    price: "ราคา",
    volume: "จำนวน",
    volumePlac: "กรุณาใส่จำนวน",
    coinCurrency: "เหรียญ",
    currentCommission: "การสั่งซื้อปัจจุบัน",
    historyCommission: "การสั่งซื้อในอดีต",
    introduction: "คำอธิบาย",
    wholeAll: "ทั้งหมด",
    transactionRecords: "บันทึกการทำธุรกรรม",
    tradingPairs: "คู่การทำธุรกรรม",
    aTurnover: "จำนวนการทำธุรกรรม",
    newPrice: "ราคาล่าสุด",
    orderNum: "เลขที่คำสั่งซื้อ",
    status: "สถานะ",
    entrustmentTime: "เวลาสั่งซื้อ",
    openHouse: "เปิดสถานะ",
    openHousePrice: "ราคาเปิดสถานะ",
    nowText: "ปัจจุบัน",
    netWorth: "มูลค่าสุทธิ",
    riskLevel: "ระดับความเสี่ยง",
    takeProfit: "ตัดขาดทุนกำไร",
    profitPlace: "กรุณาใส่จำนวนสำหรับตัดขาดทุนกำไร",
    stopLoss: "หยุดขาดทุน",
    lossPlace: "กรุณาใส่จำนวนสำหรับหยุดขาดทุน",
    bondMoney: "หลักประกัน",
    fontMoney: "ราคาต่อหน่วย",
    font: "หน่วย",
    buyMoreBtn: "ซื้อเพื่อทำกำไร (มองว่าจะขึ้น)",
    buyLessBtn: "ขายเพื่อทำกำไร (มองว่าจะลง)",
    recharge: "เติมเงิน",
    rechargeTips: "รายละเอียดการเติมเงิน",
    withdraw: "ถอนเงิน",
    floatingProfit: "กำไร/ขาดทุนลอยตัว (บัญชีจริง)",
    accountBalance: "ยอดคงเหลือในบัญชี",
    accountChoose: "เลือกบัญชี",
    accountChoosePlac: "โปรดเลือกบัญชี",
    freeMargin: "หลักประกันที่ใช้ได้",
    usedMargin: "หลักประกันที่ใช้ไปแล้ว",
    accountNet: "มูลค่าสุทธิของบัญชี",
    freezeAmount: "จำนวนที่แช่แข็ง",
    coinAccount: "บัญชีเหรียญ",
    contractAccount: "บัญชีสัญญา",
    freezeText: "แช่แข็ง",
    disassemble: "แปลง (USD)",
    transactionPrice: "ราคาการซื้อขาย",
    openingNum: "จำนวนเปิดสถานะ",
    guaranteePrice: "ทรัพย์สินที่รับประกัน",
    nowPrice: "ราคากำลังปัจจุบัน",
    takeProfitPrice: "ราคาตัดขาดทุนกำไร",
    stopLossPrice: "ราคาหยุดขาดทุน",
    hangingPrice: "ราคาที่แขวนไว้",
    historyOrders: "ประวัติคำสั่งซื้อขาย",
    typeCurrency: "ประเภท/สกุลเงิน",
    buyMoney: "จำนวนเงินที่ซื้อ",
    sellPrice: "ราคาขาย",
    buyPrice: "ราคาซื้อ",
    profit: "กำไร",
    buyTime: "เวลาที่ซื้อ",
    finshTime: "เวลาที่เสร็จสมบูรณ์",
    sellTime: "เวลาที่ขาย",
    chainName: "ชื่อเครือข่าย",
    chainChoosePlac: "เลือกสกุลเงิน",
    chainNumPlac: "กรุณาใส่จำนวน",
    xxpectedRechargeTitle: "จำนวน USDT ที่คาดว่าจะเติม",
    uploadImgText: "อัปโหลดรูปภาพ",
    markPaid: "ทำเครื่องหมายว่าชำระแล้ว",
    payMoney: "จำนวนเงินที่ชำระ",
    payTime: "เวลาที่ชำระ",
    payStatus: "สถานะการชำระ",
    withdrawalDetails: "รายละเอียดการถอนเงิน",
    remark: "หมายเหตุ",
    rechargeAmount: "จำนวนเงินที่เติม",
    withdrawalAmount: "จำนวนเงินที่ถอน",
    withdrawalAmountPlac: "กรุณาใส่จำนวนเงินที่ถอน",
    withdrawalTips: "จำนวนเงินถอนขั้นต่ำคือ",
    nowNumberError: "จำนวนเงินถอนปัจจุบันน้อยกว่าจำนวนขั้นต่ำ",
    submitBtnText: "ยืนยันการส่ง",
    orderNumber: "จำนวนคำสั่งซื้อ",
  },
  order: {
    buyIng: "กำลังซื้อขาย",
    closing: "กำลังปิดสถานะ",
    closingText: "ปิดสถานะ",
    hasClosed: "สถานะถูกปิดแล้ว",
    waitPay: "รอชำระเงิน",
    rechargeFail: "เติมเงินไม่สำเร็จ",
    rechargeSuccess: "เติมเงินสำเร็จ",
    callbacking: "กำลังเรียกกลับ",
  },
  请先完成实名认证: "โปรดทำการยืนยันตัวตนให้เสร็จสิ้นก่อน",
  参数错误: "ข้อมูลผิดพลาด",
  数据未找到: "ไม่พบข้อมูล",
  还未创建账户: "ยังไม่ได้สร้างบัญชี",
  "钱包未找到,请先添加钱包": "ไม่พบกระเป๋าเงิน, กรุณาเพิ่มกระเป๋าเงินก่อน",
  资金钱包余额不足: "ยอดเงินในกระเป๋าเงินไม่เพียงพอ",
  入金成功: "ฝากเงินสำเร็จ",
  项目不存在: "โครงการไม่มีอยู่",
  "最少存入数量为：": "จำนวนฝากขั้นต่ำคือ：",
  success: "สำเร็จ",
  数量不可低于0: "จำนวนไม่สามารถต่ำกว่า 0",
  账户余额不足: "ยอดเงินในบัญชีไม่เพียงพอ",
  你的冻结余额为0: "ยอดเงินแช่แข็งของคุณเป็น 0",
  存币成功: "ฝากเหรียญสำเร็จ",
  找不到存单: "ไม่พบใบฝาก",
  非法操作: "การดำเนินการผิดกฎหมาย",
  存单状态异常: "สถานะใบฝากผิดปกติ",
  毁约成功: "ยกเลิกสัญญาสำเร็จ",
  功能暂不可用: "ฟังก์ชันไม่พร้อมใช้งาน",
  订单不能取消: "ไม่สามารถยกเลิกคำสั่งซื้อขายได้",
  提取数量超过持有数量: "จำนวนที่ถอนเกินกว่าที่ถือครอง",
  找不到交易对: "ไม่พบคู่การซื้อขาย",
  帐户已被冻结: "บัญชีถูกแช่แข็ง",
  请求异常: "คำขอผิดพลาด",
  状态异常: "สถานะผิดปกติ",
  "取消失败:": "ยกเลิกไม่สำเร็จ:",
  取消成功: "ยกเลิกสำเร็จ",
  pid不能为空: "PID ห้ามว่าง",
  产品不存在: "ผลิตภัณฑ์ไม่มีอยู่",
  配置错误: "การตั้งค่าผิดพลาด",
  金额错误: "จำนวนเงินผิดพลาด",
  项目状态错误: "สถานะโครงการผิดพลาด",
  暂无数据: "ไม่มีข้อมูลในขณะนี้",
  文件大小超出: "ขนาดไฟล์เกินกำหนด",
  该文件已存在: "ไฟล์นี้มีอยู่แล้ว",
  文件类型不对: "ประเภทไฟล์ไม่ถูกต้อง",
  上传失败: "อัปโหลดไม่สำเร็จ",
  订单取消成功: "ยกเลิกคำสั่งซื้อขายสำเร็จ",
  配置不存在: "การตั้งค่าไม่มีอยู่",
  您尚未激活此交易对的交易功能:
    "คุณยังไม่ได้เปิดใช้งานฟังก์ชันการซื้อขายสำหรับคู่การซื้อขายนี้",
  "您的交易当前正在关闭，此时无法继续交易":
    "การทำธุรกรรมของคุณกำลังถูกปิด ณ ขณะนี้ คุณไม่สามารถดำเนินการต่อได้",
  类型错误: "ประเภทผิดพลาด",
  此功能当前未打开: "ฟังก์ชันนี้ปัจจุบันยังไม่เปิดใช้งาน",
  限价交易不能以低于当前价格的价格出售:
    "การเทรดแบบจำกัดราคาไม่สามารถขายในราคาที่ต่ำกว่าราคาปัจจุบันได้",
  提交成功: "ส่งสำเร็จ",
  此功能系统未打开: "ระบบยังไม่เปิดใช้งานฟังก์ชันนี้",
  止盈止损价格不能为0: "ราคาตัดขาดทุนกำไรและหยุดขาดทุนไม่สามารถเป็น 0 ได้",
  找不到交易记录: "ไม่พบประวัติการซื้อขาย",
  "买入（多头）止盈价格不能低于开盘价和当前价格":
    "ราคาตัดขาดทุนกำไรจากการซื้อ (Long) ไม่สามารถต่ำกว่าราคาเปิดและราคาปัจจุบันได้",
  "买入（做多）止损价格不能高于开盘价和当前价格":
    "ราคาหยุดขาดทุนจากการซื้อ (Long) ไม่สามารถสูงกว่าราคาเปิดและราคาปัจจุบันได้",
  "卖出（卖空）止盈价格不能高于开盘价和当前价格":
    "ราคาตัดขาดทุนกำไรจากการขาย (Short) ไม่สามารถสูงกว่าราคาเปิดและราคาปัจจุบันได้",
  "卖出（卖空）止损价格不能低于开盘价和当前价格":
    "ราคาหยุดขาดทุนจากการขาย (Short) ไม่สามารถต่ำกว่าราคาเปิดและราคาปัจจุบันได้",
  设置成功: "ตั้งค่าสำเร็จ",
  设置失败: "ตั้งค่าไม่สำเร็จ",
  购买方向传输错误: "การส่งข้อมูลทิศทางการซื้อมีข้อผิดพลาด",
  取消成功: "ยกเลิกสำเร็จ",
  "取消失败：": "ยกเลิกไม่สำเร็จ：",
  "取消失败：用户钱包不存在": "ยกเลิกไม่สำเร็จ：กระเป๋าเงินของผู้ใช้ไม่มีอยู่",
  "交易记录不存在或已被取消。请刷新并重试":
    "ประวัติการซื้อขายไม่มีอยู่หรือถูกยกเลิกแล้ว โปรดรีเฟรชและลองใหม่",
  "找不到钱包，请先添加钱包": "ไม่พบกระเป๋าเงิน, โปรดเพิ่มกระเป๋าเงินก่อน",
  "余额不足，不能小于": "ยอดเงินไม่เพียงพอ ไม่สามารถน้อยกว่า",
  "扣款失败：": "การหักเงินไม่สำเร็จ：",
  "手续费扣减失败：": "การหักค่าธรรมเนียมไม่สำเร็จ：",
  未找到数据: "ไม่พบข้อมูล",
  未经授权的操作: "การดำเนินการโดยไม่ได้รับอนุญาต",
  "交易状态异常，请不要重新提交": "สถานะการซื้อขายผิดปกติ โปรดอย่าส่งคำขอใหม่",
  "关闭失败，请重试": "การปิดไม่สำเร็จ โปรดลองใหม่",
  "交易异常，无法平仓": "การทำธุรกรรมผิดปกติ ไม่สามารถปิดสถานะได้",
  请输入账号: "กรุณาใส่หมายเลขบัญชี",
  请输入密码: "กรุณาใส่รหัสผ่าน",
  用户不存在: "ผู้ใช้ไม่มีอยู่",
  密码错误: "รหัสผ่านผิดพลาด",
  "您的帐户已被冻结。详情请咨询客服。":
    "บัญชีของคุณถูกแช่แข็ง สำหรับรายละเอียดเพิ่มเติม โปรดติดต่อฝ่ายบริการลูกค้า",
  密码只能在6到16位之间: "รหัสผ่านต้องอยู่ระหว่าง 6 ถึง 16 ตัวอักษร",
  验证码错误: "รหัสยืนยันผิดพลาด",
  用户名已存在: "ชื่อผู้ใช้นี้มีอยู่แล้ว",
  请填写正确的邀请码: "กรุณากรอกรหัสเชิญที่ถูกต้อง",
};
