import { useRoute, useStore } from "@/utils/vueApi";
import { ref, watch, getCurrentInstance, nextTick } from "vue";
import _ from "lodash";
export function useUser() {
  const vm = getCurrentInstance();
  const store = useStore();
  const isLogin = ref(false);
  watch(
    () => store.state.user.isLogin,
    (val) => {
        isLogin.value = val == '1' ? true : false;
    },
    { deep: true, immediate: true }
  );
  return {
    isLogin,
  };
}
