// 中文
export default {
  loginInfo: {
    loginTitle: "账号登入",
    registerTitle: "账号注册",
    loginTip: "欢迎回来！使用您的电子邮箱或手机号登入",
    registerTip: "使用您的电子邮箱或手机号进行注册",
    userNamePlc: "请输入账号",
    userPassPlc: "请输入密码",
    login: "登入",
    register: "注册",
    overtime: "登录超时，请重新登录",
    loginSuccess: "登入成功",
    registerSuccess: "注册成功",
    userNameTip: "请输入账号",
    userPassTip: "请输入密码",
    userOldPassTip: "请输入旧密码",
    userNewPassTip: "请输入新密码",
    userPassAgainTip: "请再次输入新密码",
    errorPassTip: "两次密码不一致",
    userPassSureTip: "请再次输入密码",
    jumpPageText: "当前页面需要登录查看，请登录",
  },
  tips: {
    loginoutSuccess: "退出登录成功",
    tip: "提示",
    success: "成功",
    error: "失败",
    pleaseSelect: "请选择",
    pleaseEnter: "请输入",
    pleaseUpload: "请上传",
    cancelText: "取消",
    sureText: "确定",
    submitText: "提交",
    uploadImg: "请上传正确的图片格式！",
    uploadImgSize: "请上传小于5M的图片！",
    inputCode: "输入验证码",
    inputNewEmail: "输入新邮箱",
    inputSureEmail: "输入正确格式邮箱",
    getCodeText: "获取验证码",
    editText: "修改",
    viewText: "查看",
    reGetCodeText: "后重新获取",
    editPassTip: "修改登录密码",
    editPayTip: "修改支付密码",
    loadingText: "加载中",
    addText: "新增",
    sureRelieveText: "确认解绑吗？",
    sureClosing: "确认平仓吗？",
    pleaseRechargeChoose: "请选择充值通道",
    pleaseRechargeInput: "请输入充值金额",
  },
  userText: {
    primaryCertification: "初级认证",
    advancedCertification: "高级认证",
    hasAuth: "已认证",
    noAuth: "未认证",
    auditIng: "审核中",
    userInfo: "个人信息",
    idCardInfo: "身份证信息",
    cardInfo: "证件信息",
    startBegin: "立即开始",
    authTip: "初级认证后可进行高级认证",
    userName: "名字",
    sureName: "姓氏",
    cardNumber: "证件编号",
    cardType: "证件类型",
    cityName: "国家地区",
    addressName: "地址",
    idCardType: "身份证",
    passport: "护照",
    drivingLicense: "驾驶执照",
    idCardFront: "身份证正面",
    idCardBack: "身份证反面",
    payPassText: "支付密码",
    loginPassText: "登录密码",
    bindEmailText: "绑定邮箱",
    dataStatistics: "数据统计",
    transactionVarieties: "历史交易品种",
    accuracy: "准确率",
    currentPosition: "当前持仓",
    totalOrder: "总订单",
    profitOrder: "盈利订单",
    lossOrder: "亏损订单",
    successOrder: "成功做多订单",
    successShortOrder: "成功做空订单",
    yesterdayTransaction: "昨日交易状况",
    profitText: "收益",
    historyFollow: "历史跟随人数",
    fictitiousContractName: "虚拟币秒合约",
    orderRatio: "订单占比",
    buyNumber: "买入单数",
    sellNumber: "卖出单数",
    metalsSecondContract: "外汇/贵金属秒合约",
    virtualCoinContract: "虚拟币合约",
    preciousMetalContracts: "外汇/贵金属合约",
    bindDigitalAddress: "绑定数字地址",
    digitalAddress: "数字地址",
    bindText: "绑定",
    relieveText: "解除",
    addBankText: "添加数字钱包",
    bankAddress: "钱包地址",
    rechargeCurrency: "充值币种",
    bankRecharge: "储蓄卡充值",
    manualService: "人工充值服务",
  },
  // 顶部导航
  headerText: {
    recharge: "快速充值",
    // 个人中心
    memberCenter: "会员中心",
    switchAccounts: "切换账号",
    bankCard: "银行卡包",
    investmentStatistics: "投资统计",
    logOut: "退出登录",
    // 右上角菜单
    helpCenter: "帮助中心",
    aboutSelf: "关于我们",
    startBegin: "新手入门",
    // 扫码提示
    scanText: "请扫描此二维码",
    // 下载提示
    downText: "下载",
    liaison: "联络",
    customerService: "客服",
    login: "登录/注册",
    accountSet: "账户设置",
  },
  // 菜单
  menu: {
    quotation: "行情",
    transactionType: "交易种类",
    transaction: "交易",
    currencytransaction: "币币交易",
    fiatTransaction: "法币交易",
    contract: "合约",
    assets: "资产",
    secondContract: "秒合约",
  },
  // 榜单
  rankText: {
    priceIncrease: "涨幅榜",
    transactionAmount: "成交额榜",
    name: "名称",
    nowPrice: "最新价",
    transactionType: "交易品种",
    amount: "量",
    operation: "操作",
    futures: "期货",
    priceRate: "涨跌幅",
    emptyText: "暂无数据",
  },
  // 首页底部
  homeFooter: {
    joinUs: "欢迎加入NYSEIMF，让我们一起共建NYSEIMF蓝图 共同建造NYSEIMF辉煌",
  },
  // 行情
  market: {
    typeName: "类型",
    digitalCurrency: "数字货币",
    nobleMetal: "贵金属",
    internationalFutures: "国际期货",
    currency: "币种",
    foreignExchange: "外汇",
    marketText: "市场",
    productName: "产品",
    dealPrice: "成交价",
    direction: "方向",
    time: "时间",
    profitLoss: "盈亏",
    beginPrice: "开盘",
    highestPrice: "最高",
    lowestPrice: "最低",
    turnover: "成交量",
    closePrice: "封盘",
    buyMore: "买多",
    buyEmpty: "买空",
    doMore: "做多",
    doLess: "做空",
    hasNum: "持仓",
    tip: "选择到期时间(左滑更高收益)",
    second: "秒",
    numberInput: "投资金额",
    numberInputPlac: "请输入投资金额",
    canUse: "可用",
    balance: "余额",
    serviceCharge: "手续费",
    commission: "市价委托",
    commissionText: "市价",
    fixedPrice: "限价委托",
    fixedText: "限价",
    commissionPlac: "请输入委托金额",
    area: "范围",
    price: "价格",
    volume: "数量",
    volumePlac: "请输入数量",
    coinCurrency: "币币",
    currentCommission: "当前委托",
    historyCommission: "历史委托",
    introduction: "简介",
    wholeAll: "全部",
    transactionRecords: "交易记录",
    tradingPairs: "交易对",
    aTurnover: "交易额",
    newPrice: "最新价格",
    orderNum: "订单号",
    status: "状态",
    entrustmentTime: "委托时间",
    openHouse: "开仓",
    openHousePrice: "开仓价",
    nowText: "当前",
    netWorth: "净值",
    riskLevel: "风险度",
    takeProfit: "止盈",
    profitPlace: "请输入止盈金额",
    stopLoss: "止损",
    lossPlace: "请输入止损金额",
    bondMoney: "保证金",
    fontMoney: "每手价格",
    font: "手",
    buyMoreBtn: "买入做多（看涨）",
    buyLessBtn: "卖出做空（看跌）",
    recharge: "充值",
    rechargeTips: "充值明细",
    withdraw: "提现",
    floatingProfit: "浮动盈亏(实盘)",
    accountBalance: "账号余额",
    accountChoose: "选择账号",
    accountChoosePlac: "请选择账号",
    freeMargin: "可用保证金",
    usedMargin: "已用保证金",
    accountNet: "账户净值",
    freezeAmount: "冻结金额",
    coinAccount: "币币账户",
    contractAccount: "合约账户",
    freezeText: "冻结",
    disassemble: "折合(USD)",
    transactionPrice: "交易价格",
    openingNum: "开仓数量",
    guaranteePrice: "担保资产",
    nowPrice: "当前价",
    takeProfitPrice: "止盈价",
    stopLossPrice: "止损价",
    hangingPrice: "挂单价",
    historyOrders: "交易历史订单",
    typeCurrency: "类型/币种",
    buyMoney: "买入金额",
    sellPrice: "卖出价格",
    buyPrice: "购买价",
    profit: "盈利",
    buyTime: "买入时间",
    finshTime: "完成时间",
    sellTime: "卖出时间",
    chainName: "链名称",
    chainChoosePlac: "选择币种",
    chainNumPlac: "请输入数量",
    xxpectedRechargeTitle: "预计充值 USDT 数额",
    uploadImgText: "上传图片",
    markPaid: "标记已支付",
    payMoney: "支付金额",
    payTime: "支付时间",
    payStatus: "支付状态",
    withdrawalDetails: "提现明细",
    remark: "备注",
    rechargeAmount: "充值金额",
    withdrawalAmount: "提现金额",
    withdrawalAmountPlac: "请输入提现金额",
    withdrawalTips: "最低提现金额为",
    nowNumberError: "当前提现金额小于最小提现金额",
    submitBtnText: "确认提交",
    orderNumber: "下单数量",
  },
  order: {
    buyIng: "交易中",
    closing: "平仓中",
    closingText: "平仓",
    hasClosed: "已平仓",
    waitPay: "待付款",
    rechargeFail: "充值失败",
    rechargeSuccess: "充值成功",
    callbacking: "回调中",
  },
  请先完成实名认证: "请先完成实名认证",
  参数错误: "参数错误",
  数据未找到: "数据未找到",
  还未创建账户: "还未创建账户",
  "钱包未找到,请先添加钱包": "钱包未找到,请先添加钱包",
  资金钱包余额不足: "资金钱包余额不足",
  入金成功: "入金成功",
  项目不存在: "项目不存在",
  "最少存入数量为：": "最少存入数量为：",
  success: "成功",
  数量不可低于0: "数量不可低于0",
  账户余额不足: "账户余额不足",
  你的冻结余额为0: "你的冻结余额为0",
  存币成功: "存币成功",
  找不到存单: "找不到存单",
  非法操作: "非法操作",
  存单状态异常: "存单状态异常",
  毁约成功: "毁约成功",
  功能暂不可用: "功能暂不可用",
  订单不能取消: "订单不能取消",
  提取数量超过持有数量: "提取数量超过持有数量",
  找不到交易对: "找不到交易对",
  帐户已被冻结: "帐户已被冻结",
  请求异常: "请求异常",
  状态异常: "状态异常",
  "取消失败:": "取消失败:",
  取消成功: "取消成功",
  pid不能为空: "pid不能为空",
  产品不存在: "产品不存在",
  配置错误: "配置错误",
  金额错误: "金额错误",
  项目状态错误: "项目状态错误",
  暂无数据: "暂无数据",
  文件大小超出: "文件大小超出",
  该文件已存在: "该文件已存在",
  文件类型不对: "文件类型不对",
  上传失败: "上传失败",
  订单取消成功: "订单取消成功",
  配置不存在: "配置不存在",
  您尚未激活此交易对的交易功能: "您尚未激活此交易对的交易功能",
  "您的交易当前正在关闭，此时无法继续交易":
    "您的交易当前正在关闭，此时无法继续交易",
  类型错误: "类型错误",
  此功能当前未打开: "此功能当前未打开",
  限价交易不能以低于当前价格的价格出售: "限价交易不能以低于当前价格的价格出售",
  提交成功: "提交成功",
  此功能系统未打开: "此功能系统未打开",
  止盈止损价格不能为0: "止盈止损价格不能为0",
  找不到交易记录: "找不到交易记录",
  "买入（多头）止盈价格不能低于开盘价和当前价格":
    "买入（多头）止盈价格不能低于开盘价和当前价格",
  "买入（做多）止损价格不能高于开盘价和当前价格":
    "买入（做多）止损价格不能高于开盘价和当前价格",
  "卖出（卖空）止盈价格不能高于开盘价和当前价格":
    "卖出（卖空）止盈价格不能高于开盘价和当前价格",
  "卖出（卖空）止损价格不能低于开盘价和当前价格":
    "卖出（卖空）止损价格不能低于开盘价和当前价格",
  设置成功: "设置成功",
  设置失败: "设置失败",
  购买方向传输错误: "购买方向传输错误",
  取消成功: "取消成功",
  "取消失败：": "取消失败：",
  "取消失败：用户钱包不存在": "取消失败：用户钱包不存在",
  "交易记录不存在或已被取消。请刷新并重试":
    "交易记录不存在或已被取消。请刷新并重试",
  "找不到钱包，请先添加钱包": "找不到钱包，请先添加钱包",
  "余额不足，不能小于": "余额不足，不能小于",
  "扣款失败：": "扣款失败：",
  "手续费扣减失败：": "手续费扣减失败：",
  未找到数据: "未找到数据",
  未经授权的操作: "未经授权的操作",
  "交易状态异常，请不要重新提交": "交易状态异常，请不要重新提交",
  "关闭失败，请重试": "关闭失败，请重试",
  "交易异常，无法平仓": "交易异常，无法平仓",
  请输入账号: "请输入账号",
  请输入密码: "请输入密码",
  用户不存在: "用户不存在",
  密码错误: "密码错误",
  "您的帐户已被冻结。详情请咨询客服。": "您的帐户已被冻结。详情请咨询客服。",
  密码只能在6到16位之间: "密码只能在6到16位之间",
  验证码错误: "验证码错误",
  用户名已存在: "用户名已存在",
  请填写正确的邀请码: "请填写正确的邀请码",
};
