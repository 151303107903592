import Vue from "vue";
import VueRouter from "vue-router";
import layout from "@/layout/index.vue";
import innerPage from "./innerPage";
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    // name: "layout",
    component: layout,
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/home.vue"),
      },
      // 左侧菜单页面合集
      innerPage,
      {
        path: "login",
        name: "login",
        component: () => import("@/views/login.vue"),
      },
      {
        path: "register",
        name: "register",
        component: () => import("@/views/register.vue"),
      },
      // 行情
      {
        path: "market",
        name: "market",
        component: () => import("@/views/market/index.vue"),
      },
      // 交易
      {
        path: "marketDetail",
        name: "marketDetail",
        component: () => import("@/views/market/marketDetail.vue"),
      },
      // 交易历史订单
      {
        path: "marketRecord",
        name: "marketRecord",
        component: () => import("@/views/market/marketRecord.vue"),
      },
      // 合约
      {
        path: "marketContract",
        name: "marketContract",
        component: () => import("@/views/market/marketContract.vue"),
      },
      // 币币交易
      {
        path: "marketCurrency",
        name: "marketCurrency",
        component: () => import("@/views/market/marketCurrency.vue"),
      },
      // 交易记录
      {
        path: "transactionRecords",
        name: "transactionRecords",
        component: () => import("@/views/market/transactionRecords.vue"),
      },
      // 资产
      {
        path: "assetsPage",
        name: "assetsPage",
        component: () => import("@/views/assetsPage/index.vue"),
      },
      // 交易历史订单
      {
        path: "assetsRecord",
        name: "assetsRecord",
        component: () => import("@/views/assetsPage/assetsRecord.vue"),
      },
      // 充值
      {
        path: "assetsRecharge",
        name: "assetsRecharge",
        component: () => import("@/views/assetsPage/assetsRecharge.vue"),
      },
      // 充值明细
      {
        path: "rechargeDetail",
        name: "rechargeDetail",
        component: () => import("@/views/assetsPage/rechargeDetail.vue"),
      },
      // 提取
      {
        path: "assetsExtract",
        name: "assetsExtract",
        component: () => import("@/views/assetsPage/assetsExtract.vue"),
      },
      // 投资统计
      {
        path: "investmentStatistics",
        name: "investmentStatistics",
        component: () => import("@/views/userMenu/investmentStatistics.vue"),
      },
       // 银行钱包
       {
        path: "bankWallet",
        name: "bankWallet",
        component: () => import("@/views/userMenu/bankWallet.vue"),
      },
      {
        path: "404",
        name: "error",
        component: () => import("@/views/404.vue"),
      },
      {
        path: "*",
        redirect: "404",
      },
    ],
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    //x代表横坐标，y代表纵坐标
    return { x: 0, y: 0 };
  },
});

export default router;
