// 语言配置
import { ref, computed, getCurrentInstance } from "vue";

export function useLang() {
  const vm = getCurrentInstance();
  const lang = ref(localStorage.getItem("lang") || "zh");
  const langList = ref([
    {
      label: "English",
      src: require("../assets/images/lang/en.png"),
      value: "en",
    },
    {
      label: "简体中文",
      src: require("../assets/images/lang/zh.png"),
      value: "zh",
    },
    {
      label: "繁體中文",
      src: require("../assets/images/lang/tw.png"),
      value: "tw",
    },
    {
      label: "ไทย",
      src: require("../assets/images/lang/th.png"),
      value: "th",
    },
    {
      label: "日本語",
      src: require("../assets/images/lang/jp.png"),
      value: "jp",
    },
    {
      label: "Español",
      src: require("../assets/images/lang/es.png"),
      value: "es",
    },
    {
      label: "Français",
      src: require("../assets/images/lang/fr.png"),
      value: "fr",
    },
    {
      label: "Deutsch",
      src: require("../assets/images/lang/de.png"),
      value: "de",
    },
    {
      label: "한국어",
      src: require("../assets/images/lang/ko.svg"),
      value: "ko",
    },
    {
      label: "Tiếng Việt",
      src: require("../assets/images/lang/vi.svg"),
      value: "vi",
    },
  ]);
  // 获取当前语言对象
  const getLangObject = computed(() => {
    return langList.value.find((item) => item.value === lang.value);
  });
  // 设置语言
  const setLan = (val) => {
    localStorage.setItem("lang", val);
    lang.value = val;
    vm.proxy.$i18n.locale = val; //切换为英文
    // location.reload();
  };
  return { lang, setLan, langList, getLangObject };
}
