// 德国
export default {
  loginInfo: {
    loginTitle: "Anmeldung",
    registerTitle: "Registrierung",
    loginTip:
      "Willkommen zurück! Bitte melden Sie sich mit Ihrer E-Mail- oder Handynummer an",
    registerTip:
      "Bitte registrieren Sie sich mit Ihrer E-Mail- oder Handynummer",
    userNamePlc: "Bitte geben Sie Ihr Konto ein",
    userPassPlc: "Bitte geben Sie Ihr Passwort ein",
    login: "Anmelden",
    register: "Registrieren",
    overtime: "Anmeldezeitüberschreitung, bitte melden Sie sich erneut an",
    loginSuccess: "Anmeldung erfolgreich",
    registerSuccess: "Registrierung erfolgreich",
    userNameTip: "Bitte geben Sie Ihr Konto ein",
    userPassTip: "Bitte geben Sie Ihr Passwort ein",
    userOldPassTip: "Bitte geben Sie Ihr altes Passwort ein",
    userNewPassTip: "Bitte geben Sie Ihr neues Passwort ein",
    userPassAgainTip: "Bitte geben Sie Ihr neues Passwort erneut ein",
    errorPassTip: "Passwörter stimmen nicht überein",
    userPassSureTip: "Bitte geben Sie Ihr Passwort erneut ein",
    jumpPageText:
      "Diese Seite erfordert eine Anmeldung zum Anzeigen, bitte melden Sie sich an",
  },
  tips: {
    loginoutSuccess: "Abmeldung erfolgreich",
    tip: "Hinweis",
    success: "Erfolg",
    error: "Fehler",
    pleaseSelect: "Bitte wählen",
    pleaseEnter: "Bitte eingeben",
    pleaseUpload: "Bitte hochladen",
    cancelText: "Abbrechen",
    sureText: "Bestätigen",
    submitText: "Absenden",
    uploadImg: "Bitte laden Sie das richtige Bildformat hoch!",
    uploadImgSize: "Bitte laden Sie ein Bild kleiner als 5M hoch!",
    inputCode: "Geben Sie den Bestätigungscode ein",
    inputNewEmail: "Neue E-Mail eingeben",
    inputSureEmail: "Bitte geben Sie die richtige E-Mail-Format ein",
    getCodeText: "Bestätigungscode erhalten",
    editText: "Bearbeiten",
    viewText: "Anzeigen",
    reGetCodeText: "Erneut nach",
    editPassTip: "Passwort ändern",
    editPayTip: "Zahlungspasswort ändern",
    loadingText: "Laden",
    addText: "Hinzufügen",
    sureRelieveText: "Sind Sie sicher, dass Sie sich trennen wollen?",
    sureClosing: "Sind Sie sicher, dass Sie schließen wollen?",
    pleaseRechargeChoose: "Bitte wählen Sie den Aufladekanal",
    pleaseRechargeInput: "Bitte geben Sie den Aufladebetrag ein",
  },
  userText: {
    primaryCertification: "Primäre Zertifizierung",
    advancedCertification: "Erweiterte Zertifizierung",
    hasAuth: "Zertifiziert",
    noAuth: "Nicht zertifiziert",
    auditIng: "In Prüfung",
    userInfo: "Persönliche Informationen",
    idCardInfo: "Ausweisinformationen",
    cardInfo: "Dokumentinformationen",
    startBegin: "Jetzt beginnen",
    authTip:
      "Nach der primären Zertifizierung können Sie die erweiterte Zertifizierung fortsetzen",
    userName: "Vorname",
    sureName: "Nachname",
    cardNumber: "Dokumentnummer",
    cardType: "Dokumenttyp",
    cityName: "Land/Region",
    addressName: "Adresse",
    idCardType: "Ausweis",
    passport: "Passport",
    drivingLicense: "Führerschein",
    idCardFront: "Ausweisvorderseite",
    idCardBack: "Ausweisrückseite",
    payPassText: "Zahlungspasswort",
    loginPassText: "Anmeldepasswort",
    bindEmailText: "E-Mail binden",
    dataStatistics: "Datenstatistik",
    transactionVarieties: "Historische Transaktionsarten",
    accuracy: "Genauigkeit",
    currentPosition: "Aktuelle Position",
    totalOrder: "Gesamtbestellungen",
    profitOrder: "Profitbestellungen",
    lossOrder: "Verlustbestellungen",
    successOrder: "Erfolgreiche Long-Bestellungen",
    successShortOrder: "Erfolgreiche Short-Bestellungen",
    yesterdayTransaction: "Transaktionsstatus von gestern",
    profitText: "Profit",
    historyFollow: "Historische Anhänger",
    fictitiousContractName: "Kryptowährungsschnellvertrag",
    orderRatio: "Auftragsverhältnis",
    buyNumber: "Kaufaufträge",
    sellNumber: "Verkaufsaufträge",
    metalsSecondContract: "Devisen/Edelmetall-Schnellvertrag",
    virtualCoinContract: "Kryptowährungskontrakt",
    preciousMetalContracts: "Devisen/Edelmetallkontrakt",
    bindDigitalAddress: "Digitale Adresse binden",
    digitalAddress: "Digitale Adresse",
    bindText: "Binden",
    relieveText: "Trennen",
    addBankText: "Digitales Wallet hinzufügen",
    bankAddress: "Wallet-Adresse",
    rechargeCurrency: "Aufladewährung",
    bankRecharge: "Bankkartenaufladung",
    manualService: "Manueller Aufladedservice",
  },
  // Obere Navigation
  headerText: {
    recharge: "Schnellaufladung",
    // Persönliches Zentrum
    memberCenter: "Mitgliederzentrum",
    switchAccounts: "Konten wechseln",
    bankCard: "Bankkartenpaket",
    investmentStatistics: "Investitionsstatistik",
    logOut: "Abmelden",
    // Rechts oben Menü
    helpCenter: "Hilfecenter",
    aboutSelf: "Über uns",
    startBegin: "Neulinge",
    // Scan Prompt
    scanText: "Bitte scannen Sie diesen QR-Code",
    // Download Prompt
    downText: "Herunterladen",
    liaison: "Kontakt",
    customerService: "Kundenservice",
    login: "Anmelden/Registrieren",
    accountSet: "Kontoeinstellungen",
  },
  // Menü
  menu: {
    quotation: "Markt",
    transactionType: "Transaktionstyp",
    transaction: "Handel",
    currencytransaction: "Währungshandel",
    fiatTransaction: "Fiat-Handel",
    contract: "Vertrag",
    assets: "Vermögenswerte",
    secondContract: "Schnellvertrag",
  },
  // Rangliste
  rankText: {
    priceIncrease: "Preissteigerungsrangliste",
    transactionAmount: "Transaktionsbetrag Rangliste",
    name: "Name",
    nowPrice: "Letzter Preis",
    transactionType: "Transaktionsart",
    amount: "Volumen",
    operation: "Operation",
    futures: "Futures",
    priceRate: "Preisveränderung",
    emptyText: "Keine Daten",
  },
  // Home Footer
  homeFooter: {
    joinUs:
      "Willkommen bei NYSEIMF, lassen Sie uns gemeinsam das NYSEIMF-Blueprint bauen und das Glanz von NYSEIMF bauen",
  },
  // Markt
  market: {
    typeName: "Typ",
    digitalCurrency: "Kryptowährung",
    nobleMetal: "Edelmetall",
    internationalFutures: "Internationale Futures",
    currency: "Währung",
    foreignExchange: "Devisenmarkt",
    marketText: "Markt",
    productName: "Produkt",
    dealPrice: "Geschäftspreis",
    direction: "Richtung",
    time: "Zeit",
    profitLoss: "Gewinn/Verlust",
    beginPrice: "Eröffnungspreis",
    highestPrice: "Höchster Preis",
    lowestPrice: "Niedrigster Preis",
    turnover: "Umsatz",
    closePrice: "Schlusspreis",
    buyMore: "Mehr kaufen",
    buyEmpty: "Leeren Kauf",
    doMore: "Kaufen",
    doLess: "Verkaufen",
    hasNum: "Position",
    tip: "Wählen Sie die Ablaufzeit (nach links für höhere Renditen)",
    second: "Sekunden",
    numberInput: "Investitionsbetrag",
    numberInputPlac: "Bitte geben Sie den Investitionsbetrag ein",
    canUse: "Verfügbar",
    balance: "Saldo",
    serviceCharge: "Servicegebühr",
    commission: "Marktauftrag",
    commissionText: "Markt",
    fixedPrice: "Limitauftrag",
    fixedText: "Limit",
    commissionPlac: "Bitte geben Sie den Auftragsbetrag ein",
    area: "Bereich",
    price: "Preis",
    volume: "Volumen",
    volumePlac: "Bitte geben Sie das Volumen ein",
    coinCurrency: "Währungshandel",
    currentCommission: "Aktueller Auftrag",
    historyCommission: "Historischer Auftrag",
    introduction: "Einführung",
    wholeAll: "Alle",
    transactionRecords: "Transaktionsaufzeichnungen",
    tradingPairs: "Handelspaare",
    aTurnover: "Transaktionsbetrag",
    newPrice: "Letzter Preis",
    orderNum: "Auftragsnummer",
    status: "Status",
    entrustmentTime: "Auftragszeit",
    openHouse: "Position öffnen",
    openHousePrice: "Öffnungspreis",
    nowText: "Aktuell",
    netWorth: "Nettowert",
    riskLevel: "Risikostufe",
    takeProfit: "Profit nehmen",
    profitPlace: "Bitte geben Sie den Gewinnbetrag ein",
    stopLoss: "Stop Loss",
    lossPlace: "Bitte geben Sie den Stop Loss-Betrag ein",
    bondMoney: "Margin",
    fontMoney: "Preis pro Hand",
    font: "Hand",
    buyMoreBtn: "Kaufen (Bullish)",
    buyLessBtn: "Verkaufen (Bearish)",
    recharge: "Aufladen",
    rechargeTips: "Aufladedetails",
    withdraw: "Abheben",
    floatingProfit: "Schwimmender Gewinn (Real)",
    accountBalance: "Kontostand",
    accountChoose: "Konto auswählen",
    accountChoosePlac: "Bitte wählen Sie ein Konto",
    freeMargin: "Verfügbare Margin",
    usedMargin: "Genutzte Margin",
    accountNet: "Kontostand",
    freezeAmount: "Gefrorener Betrag",
    coinAccount: "Währungskonto",
    contractAccount: "Vertragskonto",
    freezeText: "Gefroren",
    disassemble: "Umgebrochen (USD)",
    transactionPrice: "Transaktionspreis",
    openingNum: "Offene Position",
    guaranteePrice: "Gewährter Vermögenswert",
    nowPrice: "Aktueller Preis",
    takeProfitPrice: "Profitpreis",
    stopLossPrice: "Stop Loss-Preis",
    hangingPrice: "Hängender Preis",
    historyOrders: "Historische Transaktionsaufträge",
    typeCurrency: "Typ/Währung",
    buyMoney: "Kaufbetrag",
    sellPrice: "Verkaufspreis",
    buyPrice: "Kaufpreis",
    profit: "Profit",
    buyTime: "Kaufzeit",
    finshTime: "Abschlusszeit",
    sellTime: "Verkaufszeit",
    chainName: "Kettenname",
    chainChoosePlac: "Währung auswählen",
    chainNumPlac: "Bitte geben Sie die Menge ein",
    xxpectedRechargeTitle: "Erwarteter Aufladebetrag USDT",
    uploadImgText: "Bild hochladen",
    markPaid: "Als bezahlt markieren",
    payMoney: "Zahlungsbetrag",
    payTime: "Zahlungszeit",
    payStatus: "Zahlungsstatus",
    withdrawalDetails: "Abhebungsdetails",
    remark: "Bemerkung",
    rechargeAmount: "Aufladebetrag",
    withdrawalAmount: "Abhebungsbetrag",
    withdrawalAmountPlac: "Bitte geben Sie den Abhebungsbetrag ein",
    withdrawalTips: "Der Mindestabhebungsbetrag beträgt",
    nowNumberError:
      "Der aktuelle Abhebungsbetrag ist niedriger als der Mindestabhebungsbetrag",
    submitBtnText: "Absenden",
    orderNumber: "Auftragsnummer",
  },
  order: {
    buyIng: "Handel",
    closing: "Schließen",
    closingText: "Schließen",
    hasClosed: "Geschlossen",
    waitPay: "Warten auf Zahlung",
    rechargeFail: "Aufladen fehlgeschlagen",
    rechargeSuccess: "Aufladen erfolgreich",
    callbacking: "Rückruf",
  },
  请先完成实名认证: "Bitte zuerst die Identitätsprüfung abschließen",
  参数错误: "Parameterfehler",
  数据未找到: "Daten nicht gefunden",
  还未创建账户: "Konto wurde noch nicht erstellt",
  "钱包未找到,请先添加钱包": "Wallet nicht gefunden, bitte zuerst hinzufügen",
  资金钱包余额不足: "Unzureichender Kontostand im Wallet",
  入金成功: "Einzahlung erfolgreich",
  项目不存在: "Projekt existiert nicht",
  "最少存入数量为：": "Mindesteinzahlungsbetrag ist:",
  success: "Erfolg",
  数量不可低于0: "Menge darf nicht niedriger als 0 sein",
  账户余额不足: "Unzureichender Kontostand",
  你的冻结余额为0: "Ihr gefrorener Kontostand ist 0",
  存币成功: "Coin-Einzahlung erfolgreich",
  找不到存单: "Einzahlungsauftrag nicht gefunden",
  非法操作: "Illegale Operation",
  存单状态异常: "Einzahlungsauftragsstatus abnormal",
  毁约成功: "Breach erfolgreich",
  功能暂不可用: "Funktion ist vorübergehend nicht verfügbar",
  订单不能取消: "Auftrag kann nicht storniert werden",
  提取数量超过持有数量: "Abhebungsmenge überschreitet Bestandsmenge",
  找不到交易对: "Handelspaar nicht gefunden",
  帐户已被冻结: "Konto wurde eingefroren",
  请求异常: "Anfragefehler",
  状态异常: "Statusfehler",
  "取消失败:": "Stornierung fehlgeschlagen:",
  取消成功: "Stornierung erfolgreich",
  pid不能为空: "pid darf nicht leer sein",
  产品不存在: "Produkt existiert nicht",
  配置错误: "Konfigurationsfehler",
  金额错误: "Betragfehler",
  项目状态错误: "Projektstatusfehler",
  暂无数据: "Keine Daten",
  文件大小超出: "Dateigröße überschritten",
  该文件已存在: "Diese Datei existiert bereits",
  文件类型不对: "Falscher Dateityp",
  上传失败: "Hochladen fehlgeschlagen",
  订单取消成功: "Auftragsstornierung erfolgreich",
  配置不存在: "Konfiguration existiert nicht",
  您尚未激活此交易对的交易功能:
    "Sie haben die Transaktionsfunktion für dieses Handelspaar noch nicht aktiviert",
  "您的交易当前正在关闭，此时无法继续交易":
    "Ihre Transaktion wird derzeit geschlossen, Sie können zu diesem Zeitpunkt nicht weiter handeln",
  类型错误: "Typfehler",
  此功能当前未打开: "Diese Funktion ist derzeit nicht geöffnet",
  限价交易不能以低于当前价格的价格出售:
    "Limitaufträge können nicht zu einem niedrigeren Preis als den aktuellen Preis verkauft werden",
  提交成功: "Erfolgreich eingereicht",
  此功能系统未打开: "Dieses Funktionssystem ist nicht geöffnet",
  止盈止损价格不能为0: "Take Profit/Stop Loss-Preis darf nicht 0 sein",
  找不到交易记录: "Transaktionsaufzeichnung nicht gefunden",
  "买入（多头）止盈价格不能低于开盘价和当前价格":
    "Kauf (Long) Take Profit-Preis darf nicht niedriger als der Eröffnungspreis und der aktuelle Preis sein",
  "买入（做多）止损价格不能高于开盘价和当前价格":
    "Kauf (Long) Stop Loss-Preis darf nicht höher als der Eröffnungspreis und der aktuelle Preis sein",
  "卖出（卖空）止盈价格不能高于开盘价和当前价格":
    "Verkauf (Short) Take Profit-Preis darf nicht höher als der Eröffnungspreis und der aktuelle Preis sein",
  "卖出（卖空）止损价格不能低于开盘价和当前价格":
    "Verkauf (Short) Stop Loss-Preis darf nicht niedriger als der Eröffnungspreis und der aktuelle Preis sein",
  设置成功: "Erfolgreich eingestellt",
  设置失败: "Einstellung fehlgeschlagen",
  购买方向传输错误: "Fehler beim Übertragen der Kaufrichtung",
  取消成功: "Stornierung erfolgreich",
  "取消失败：": "Stornierung fehlgeschlagen:",
  "取消失败：用户钱包不存在":
    "Stornierung fehlgeschlagen: Benutzerwallet existiert nicht",
  "交易记录不存在或已被取消。请刷新并重试":
    "Transaktionsaufzeichnung existiert nicht oder wurde storniert. Bitte aktualisieren und erneut versuchen",
  "找不到钱包，请先添加钱包": "Wallet nicht gefunden, bitte zuerst hinzufügen",
  "余额不足，不能小于":
    "Unzureichender Kontostand, darf nicht kleiner sein als",
  "扣款失败：": "Abzug fehlgeschlagen:",
  "手续费扣减失败：": "Servicegebührabzug fehlgeschlagen:",
  未找到数据: "Daten nicht gefunden",
  未经授权的操作: "Unautorisierte Operation",
  "交易状态异常，请不要重新提交":
    "Transaktionsstatus abnormal, bitte nicht erneut einreichen",
  "关闭失败，请重试": "Schließen fehlgeschlagen, bitte erneut versuchen",
  "交易异常，无法平仓":
    "Transaktion abnormal, kann Position nicht geschlossen werden",
  请输入账号: "Bitte geben Sie Ihr Konto ein",
  请输入密码: "Bitte geben Sie Ihr Passwort ein",
  用户不存在: "Benutzer existiert nicht",
  密码错误: "Passwortfehler",
  "您的帐户已被冻结。详情请咨询客服。":
    "Ihr Konto wurde eingefroren. Bitte wenden Sie sich an den Kundenservice.",
  密码只能在6到16位之间: "Passwort muss zwischen 6 und 16 Zeichen lang sein",
  验证码错误: "Bestätigungscode-Fehler",
  用户名已存在: "Benutzername existiert bereits",
  请填写正确的邀请码: "Bitte geben Sie den richtigen Einladungscode ein",
};
