// 越南语
export default {
  loginInfo: {
    loginTitle: "Đăng nhập tài khoản",
    registerTitle: "Đăng ký tài khoản",
    loginTip:
      "Chào mừng quay lại! Đăng nhập bằng email hoặc số điện thoại của bạn",
    registerTip: "Đăng ký bằng email hoặc số điện thoại của bạn",
    userNamePlc: "Nhập tên tài khoản",
    userPassPlc: "Nhập mật khẩu",
    login: "Đăng nhập",
    register: "Đăng ký",
    overtime: "Thời gian đăng nhập hết hạn, vui lòng đăng nhập lại",
    loginSuccess: "Đăng nhập thành công",
    registerSuccess: "Đăng ký thành công",
    userNameTip: "Nhập tên tài khoản",
    userPassTip: "Nhập mật khẩu",
    userOldPassTip: "Nhập mật khẩu cũ",
    userNewPassTip: "Nhập mật khẩu mới",
    userPassAgainTip: "Nhập lại mật khẩu mới",
    errorPassTip: "Hai mật khẩu không khớp",
    userPassSureTip: "Nhập lại mật khẩu",
    jumpPageText: "Trang hiện tại yêu cầu đăng nhập để xem, vui lòng đăng nhập",
  },
  tips: {
    loginoutSuccess: "Đăng xuất thành công",
    tip: "Lưu ý",
    success: "Thành công",
    error: "Thất bại",
    pleaseSelect: "Vui lòng chọn",
    pleaseEnter: "Vui lòng nhập",
    pleaseUpload: "Vui lòng tải lên",
    cancelText: "Hủy bỏ",
    sureText: "Xác nhận",
    submitText: "Gửi đi",
    uploadImg: "Vui lòng tải lên định dạng ảnh chính xác!",
    uploadImgSize: "Vui lòng tải lên hình ảnh nhỏ hơn 5MB!",
    inputCode: "Nhập mã xác minh",
    inputNewEmail: "Nhập email mới",
    inputSureEmail: "Nhập email đúng định dạng",
    getCodeText: "Nhận mã xác minh",
    editText: "Sửa đổi",
    viewText: "Xem",
    reGetCodeText: "sau đó nhận lại",
    editPassTip: "Sửa đổi mật khẩu đăng nhập",
    editPayTip: "Sửa đổi mật khẩu thanh toán",
    loadingText: "Đang tải",
    addText: "Thêm mới",
    sureRelieveText: "Bạn có chắc muốn giải liên kết?",
    sureClosing: "Bạn có chắc muốn đóng lệnh?",
    pleaseRechargeChoose: "Vui lòng chọn kênh nạp tiền",
    pleaseRechargeInput: "Vui lòng nhập số tiền nạp",
  },
  userText: {
    primaryCertification: "Chứng thực sơ cấp",
    advancedCertification: "Chứng thực nâng cao",
    hasAuth: "Đã chứng thực",
    noAuth: "Chưa chứng thực",
    auditIng: "Đang kiểm duyệt",
    userInfo: "Thông tin cá nhân",
    idCardInfo: "Thông tin chứng minh thư",
    cardInfo: "Thông tin giấy tờ",
    startBegin: "Bắt đầu ngay",
    authTip:
      "Sau khi chứng thực sơ cấp, bạn có thể thực hiện chứng thực nâng cao",
    userName: "Tên",
    sureName: "Họ",
    cardNumber: "Số giấy tờ",
    cardType: "Loại giấy tờ",
    cityName: "Quốc gia/địa phương",
    addressName: "Địa chỉ",
    idCardType: "Chứng minh thư",
    passport: "Hộ chiếu",
    drivingLicense: "Giấy phép lái xe",
    idCardFront: "Mặt trước CMND",
    idCardBack: "Mặt sau CMND",
    payPassText: "Mật khẩu thanh toán",
    loginPassText: "Mật khẩu đăng nhập",
    bindEmailText: "Kết nối email",
    dataStatistics: "Thống kê dữ liệu",
    transactionVarieties: "Các loại giao dịch trong lịch sử",
    accuracy: "Độ chính xác",
    currentPosition: "Vị trí hiện tại",
    totalOrder: "Tổng đơn hàng",
    profitOrder: "Đơn hàng có lợi nhuận",
    lossOrder: "Đơn hàng thua lỗ",
    successOrder: "Đơn hàng mua nhiều thành công",
    successShortOrder: "Đơn hàng bán khống thành công",
    yesterdayTransaction: "Tình trạng giao dịch hôm qua",
    profitText: "Lợi nhuận",
    historyFollow: "Số người theo dõi trong lịch sử",
    fictitiousContractName: "Hợp đồng giây ảo",
    orderRatio: "Tỷ lệ đơn hàng",
    buyNumber: "Số lượng mua",
    sellNumber: "Số lượng bán",
    metalsSecondContract: "Hợp đồng giây ngoại hối/kim loại quý",
    virtualCoinContract: "Hợp đồng tiền ảo",
    preciousMetalContracts: "Hợp đồng ngoại hối/kim loại quý",
    bindDigitalAddress: "Kết nối địa chỉ số",
    digitalAddress: "Địa chỉ số",
    bindText: "Kết nối",
    relieveText: "Giải liên kết",
    addBankText: "Thêm ví số",
    bankAddress: "Địa chỉ ví",
    rechargeCurrency: "Tiền tệ nạp",
    bankRecharge: "Nạp tiền từ thẻ ngân hàng",
    manualService: "Dịch vụ nạp tiền thủ công",
  },
  // 顶部导航
  headerText: {
    recharge: "Nạp tiền nhanh",
    // 个人中心
    memberCenter: "Trung tâm thành viên",
    switchAccounts: "Chuyển đổi tài khoản",
    bankCard: "Ví ngân hàng",
    investmentStatistics: "Thống kê đầu tư",
    logOut: "Đăng xuất",
    // 右上角菜单
    helpCenter: "Trung tâm trợ giúp",
    aboutSelf: "Về chúng tôi",
    startBegin: "Bắt đầu cho người mới",
    // 扫码提示
    scanText: "Vui lòng quét mã QR này",
    // 下载提示
    downText: "Tải xuống",
    liaison: "Liên lạc",
    customerService: "Hỗ trợ khách hàng",
    login: "Đăng nhập/Đăng ký",
    accountSet: "Cài đặt tài khoản",
  },
  // 菜单
  menu: {
    quotation: "Bảng giá",
    transactionType: "Loại giao dịch",
    transaction: "Giao dịch",
    currencytransaction: "Giao dịch tiền ảo",
    fiatTransaction: "Giao dịch pháp định",
    contract: "Hợp đồng",
    assets: "Tài sản",
    secondContract: "Hợp đồng giây",
  },
  // 榜单
  rankText: {
    priceIncrease: "Bảng tăng giá",
    transactionAmount: "Bảng tổng giao dịch",
    name: "Tên",
    nowPrice: "Giá hiện tại",
    transactionType: "Loại giao dịch",
    amount: "Số lượng",
    operation: "Thao tác",
    futures: "Hợp đồng tương lai",
    priceRate: "Tỷ lệ tăng/giảm",
    emptyText: "Không có dữ liệu",
  },
  // 首页底部
  homeFooter: {
    joinUs:
      "Chào mừng đến với NYSEIMF, hãy cùng nhau xây dựng NYSEIMF và tạo nên sự rực rỡ cho NYSEIMF",
  },
  // 行情
  market: {
    typeName: "Loại",
    digitalCurrency: "Tiền ảo",
    nobleMetal: "Kim loại quý",
    internationalFutures: "Hợp đồng tương lai quốc tế",
    currency: "Tiền tệ",
    foreignExchange: "Ngoại hối",
    marketText: "Thị trường",
    productName: "Sản phẩm",
    dealPrice: "Giá giao dịch",
    direction: "Hướng",
    time: "Thời gian",
    profitLoss: "Lãi/lỗ",
    beginPrice: "Giá mở cửa",
    highestPrice: "Giá cao nhất",
    lowestPrice: "Giá thấp nhất",
    turnover: "Khối lượng giao dịch",
    closePrice: "Giá đóng cửa",
    buyMore: "Mua nhiều",
    buyEmpty: "Mua trống",
    doMore: "Làm nhiều",
    doLess: "Làm ít",
    hasNum: "Số lượng nắm giữ",
    tip: "Chọn thời gian đáo hạn (trượt trái để có lợi nhuận cao hơn)",
    second: "giây",
    numberInput: "Số tiền đầu tư",
    numberInputPlac: "Vui lòng nhập số tiền đầu tư",
    canUse: "Có sẵn",
    balance: "Số dư",
    serviceCharge: "Phí dịch vụ",
    commission: "Đặt lệnh theo thị trường",
    commissionText: "Theo thị trường",
    fixedPrice: "Đặt lệnh giới hạn",
    fixedText: "Giới hạn",
    commissionPlac: "Vui lòng nhập số tiền đặt lệnh",
    area: "Khu vực",
    price: "Giá",
    volume: "Số lượng",
    volumePlac: "Vui lòng nhập số lượng",
    coinCurrency: "Tiền ảo",
    currentCommission: "Đơn đặt hàng hiện tại",
    historyCommission: "Lịch sử đặt hàng",
    introduction: "Giới thiệu",
    wholeAll: "Tất cả",
    transactionRecords: "Bản ghi giao dịch",
    tradingPairs: "Cặp giao dịch",
    aTurnover: "Khối lượng giao dịch",
    newPrice: "Giá mới nhất",
    orderNum: "Số đơn hàng",
    status: "Trạng thái",
    entrustmentTime: "Thời gian ủy thác",
    openHouse: "Mở lệnh",
    openHousePrice: "Giá mở lệnh",
    nowText: "Hiện tại",
    netWorth: "Giá trị tài sản ròng",
    riskLevel: "Mức độ rủi ro",
    takeProfit: "Chốt lời",
    profitPlace: "Vui lòng nhập số tiền chốt lời",
    stopLoss: "Dừng lỗ",
    lossPlace: "Vui lòng nhập số tiền dừng lỗ",
    bondMoney: "Tiền cọc",
    fontMoney: "Giá mỗi tay",
    font: "tay",
    buyMoreBtn: "Mua nhiều (dự đoán tăng)",
    buyLessBtn: "Bán khống (dự đoán giảm)",
    recharge: "Nạp tiền",
    rechargeTips: "Chi tiết nạp tiền",
    withdraw: "Rút tiền",
    floatingProfit: "Lãi/lỗ nổi (thực tế)",
    accountBalance: "Số dư tài khoản",
    accountChoose: "Chọn tài khoản",
    accountChoosePlac: "Vui lòng chọn tài khoản",
    freeMargin: "Tiền cọc có sẵn",
    usedMargin: "Tiền cọc đã sử dụng",
    accountNet: "Giá trị tài sản ròng tài khoản",
    freezeAmount: "Số tiền bị đóng băng",
    coinAccount: "Tài khoản tiền ảo",
    contractAccount: "Tài khoản hợp đồng",
    freezeText: "Đóng băng",
    disassemble: "Chuyển đổi (USD)",
    transactionPrice: "Giá giao dịch",
    openingNum: "Số lượng mở lệnh",
    guaranteePrice: "Tài sản bảo đảm",
    nowPrice: "Giá hiện tại",
    takeProfitPrice: "Giá chốt lời",
    stopLossPrice: "Giá dừng lỗ",
    hangingPrice: "Giá treo lệnh",
    historyOrders: "Lịch sử đơn hàng giao dịch",
    typeCurrency: "Loại/tiền tệ",
    buyMoney: "Số tiền mua",
    sellPrice: "Giá bán",
    buyPrice: "Giá mua",
    profit: "Lãi",
    buyTime: "Thời gian mua",
    finshTime: "Thời gian hoàn thành",
    sellTime: "Thời gian bán",
    chainName: "Tên chuỗi",
    chainChoosePlac: "Chọn tiền ảo",
    chainNumPlac: "Vui lòng nhập số lượng",
    xxpectedRechargeTitle: "Số USDT dự kiến nạp",
    uploadImgText: "Tải lên ảnh",
    markPaid: "Đánh dấu đã thanh toán",
    payMoney: "Số tiền thanh toán",
    payTime: "Thời gian thanh toán",
    payStatus: "Trạng thái thanh toán",
    withdrawalDetails: "Chi tiết rút tiền",
    remark: "Ghi chú",
    rechargeAmount: "Số tiền nạp",
    withdrawalAmount: "Số tiền rút",
    withdrawalAmountPlac: "Vui lòng nhập số tiền rút",
    withdrawalTips: "Số tiền rút tối thiểu là",
    nowNumberError: "Số tiền rút hiện tại nhỏ hơn số tiền rút tối thiểu",
    submitBtnText: "Xác nhận gửi",
    orderNumber: "Số lượng đặt hàng",
  },
  order: {
    buyIng: "Đang giao dịch",
    closing: "Đang đóng lệnh",
    closingText: "Đóng lệnh",
    hasClosed: "Đã đóng lệnh",
    waitPay: "Chờ thanh toán",
    rechargeFail: "Nạp tiền thất bại",
    rechargeSuccess: "Nạp tiền thành công",
    callbacking: "Đang gọi lại",
  },
  请先完成实名认证: "Vui lòng hoàn tất xác thực danh tính",
  参数错误: "Tham số không chính xác",
  数据未找到: "Không tìm thấy dữ liệu",
  还未创建账户: "Chưa tạo tài khoản",
  "钱包未找到,请先添加钱包": "Ví chưa được tìm thấy, vui lòng thêm ví",
  资金钱包余额不足: "Số dư trong ví không đủ",
  入金成功: "Nạp tiền thành công",
  项目不存在: "Dự án không tồn tại",
  "最少存入数量为：": "Số lượng nạp tối thiểu là:",
  success: "Thành công",
  数量不可低于0: "Số lượng không được thấp hơn 0",
  账户余额不足: "Số dư tài khoản không đủ",
  你的冻结余额为0: "Số dư bị đóng băng của bạn là 0",
  存币成功: "Lưu đồng tiền thành công",
  找不到存单: "Không tìm thấy chứng từ lưu trữ",
  非法操作: "Thao tác bất hợp pháp",
  存单状态异常: "Trạng thái chứng từ lưu trữ không bình thường",
  毁约成功: "Hủy hợp đồng thành công",
  功能暂不可用: "Tính năng tạm thời không khả dụng",
  订单不能取消: "Đơn hàng không thể hủy",
  提取数量超过持有数量: "Số lượng rút vượt quá số lượng đang nắm giữ",
  找不到交易对: "Không tìm thấy cặp giao dịch",
  帐户已被冻结: "Tài khoản đã bị đóng băng",
  请求异常: "Yêu cầu bất thường",
  状态异常: "Trạng thái bất thường",
  "取消失败:": "Hủy thất bại:",
  取消成功: "Hủy thành công",
  pid不能为空: "pid không được để trống",
  产品不存在: "Sản phẩm không tồn tại",
  配置错误: "Cấu hình không chính xác",
  金额错误: "Số tiền không chính xác",
  项目状态错误: "Trạng thái dự án không chính xác",
  暂无数据: "Hiện tại không có dữ liệu",
  文件大小超出: "Kích thước tệp vượt quá giới hạn",
  该文件已存在: "Tệp này đã tồn tại",
  文件类型不对: "Loại tệp không đúng",
  上传失败: "Tải lên thất bại",
  订单取消成功: "Hủy đơn hàng thành công",
  配置不存在: "Cấu hình không tồn tại",
  您尚未激活此交易对的交易功能:
    "Bạn chưa kích hoạt chức năng giao dịch cho cặp giao dịch này",
  "您的交易当前正在关闭，此时无法继续交易":
    "Giao dịch của bạn đang được đóng, không thể tiếp tục giao dịch",
  类型错误: "Loại không chính xác",
  此功能当前未打开: "Tính năng này hiện chưa được mở",
  限价交易不能以低于当前价格的价格出售:
    "Giao dịch giá cố định không thể bán với giá thấp hơn giá hiện tại",
  提交成功: "Gửi đi thành công",
  此功能系统未打开: "Hệ thống chưa mở tính năng này",
  止盈止损价格不能为0: "Giá chốt lời và dừng lỗ không thể là 0",
  找不到交易记录: "Không tìm thấy bản ghi giao dịch",
  "买入（多头）止盈价格不能低于开盘价和当前价格":
    "Giá chốt lời (mua nhiều) không thể thấp hơn giá mở cửa và giá hiện tại",
  "买入（做多）止损价格不能高于开盘价和当前价格":
    "Giá dừng lỗ (mua nhiều) không thể cao hơn giá mở cửa và giá hiện tại",
  "卖出（卖空）止盈价格不能高于开盘价和当前价格":
    "Giá chốt lời (bán khống) không thể cao hơn giá mở cửa và giá hiện tại",
  "卖出（卖空）止损价格不能低于开盘价和当前价格":
    "Giá dừng lỗ (bán khống) không thể thấp hơn giá mở cửa và giá hiện tại",
  设置成功: "Cài đặt thành công",
  设置失败: "Cài đặt thất bại",
  购买方向传输错误: "Lỗi truyền hướng mua",
  取消成功: "Hủy thành công",
  "取消失败：": "Hủy thất bại:",
  "取消失败：用户钱包不存在": "Hủy thất bại: Ví người dùng không tồn tại",
  "交易记录不存在或已被取消。请刷新并重试":
    "Bản ghi giao dịch không tồn tại hoặc đã bị hủy. Vui lòng làm mới và thử lại",
  "找不到钱包，请先添加钱包": "Không tìm thấy ví, vui lòng thêm ví",
  "余额不足，不能小于": "Số dư không đủ, không thể ít hơn",
  "扣款失败：": "Rút tiền thất bại:",
  "手续费扣减失败：": "Phí dịch vụ trừ thất bại:",
  未找到数据: "Không tìm thấy dữ liệu",
  未经授权的操作: "Thao tác không được ủy quyền",
  "交易状态异常，请不要重新提交":
    "Trạng thái giao dịch bất thường, vui lòng không gửi lại",
  "关闭失败，请重试": "Đóng thất bại, vui lòng thử lại",
  "交易异常，无法平仓": "Giao dịch bất thường, không thể đóng lệnh",
  请输入账号: "Vui lòng nhập tài khoản",
  请输入密码: "Vui lòng nhập mật khẩu",
  用户不存在: "Người dùng không tồn tại",
  密码错误: "Mật khẩu không chính xác",
  "您的帐户已被冻结。详情请咨询客服。":
    "Tài khoản của bạn đã bị đóng băng. Chi tiết xin liên hệ hỗ trợ khách hàng.",
  密码只能在6到16位之间: "Mật khẩu phải từ 6 đến 16 ký tự",
  验证码错误: "Mã xác minh không chính xác",
  用户名已存在: "Tên người dùng đã tồn tại",
  请填写正确的邀请码: "Vui lòng nhập mã mời chính xác",
};
