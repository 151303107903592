// 法国
export default {
    loginInfo: {
      loginTitle: "Connexion au compte",
      registerTitle: "Inscription au compte",
      loginTip: "Bienvenue de retour ! Connectez-vous avec votre email ou numéro de téléphone",
      registerTip: "Inscrivez-vous avec votre email ou numéro de téléphone",
      userNamePlc: "Veuillez entrer le nom d'utilisateur",
      userPassPlc: "Veuillez entrer le mot de passe",
      login: "Se connecter",
      register: "S'inscrire",
      overtime: "La session a expiré, veuillez vous reconnecter",
      loginSuccess: "Connexion réussie",
      registerSuccess: "Inscription réussie",
      userNameTip: "Veuillez entrer le nom d'utilisateur",
      userPassTip: "Veuillez entrer le mot de passe",
      userOldPassTip: "Veuillez entrer l'ancien mot de passe",
      userNewPassTip: "Veuillez entrer le nouveau mot de passe",
      userPassAgainTip: "Veuillez confirmer le nouveau mot de passe",
      errorPassTip: "Les mots de passe ne correspondent pas",
      userPassSureTip: "Veuillez confirmer le mot de passe",
      jumpPageText: "Cette page nécessite une connexion, veuillez vous connecter",
    },
    tips: {
      loginoutSuccess: "Déconnexion réussie",
      tip: "Conseil",
      success: "Succès",
      error: "Échec",
      pleaseSelect: "Veuillez sélectionner",
      pleaseEnter: "Veuillez entrer",
      pleaseUpload: "Veuillez télécharger",
      cancelText: "Annuler",
      sureText: "Confirmer",
      submitText: "Soumettre",
      uploadImg: "Veuillez télécharger un format d'image correct !",
      uploadImgSize: "Veuillez télécharger une image de moins de 5MB !",
      inputCode: "Entrer le code de vérification",
      inputNewEmail: "Entrer la nouvelle adresse e-mail",
      inputSureEmail: "Entrer une adresse e-mail au format correct",
      getCodeText: "Obtenir le code de vérification",
      editText: "Modifier",
      viewText: "Voir",
      reGetCodeText: "Ressayer après",
      editPassTip: "Modifier le mot de passe de connexion",
      editPayTip: "Modifier le mot de passe de paiement",
      loadingText: "Chargement en cours",
      addText: "Ajouter",
      sureRelieveText: "Êtes-vous sûr de vouloir délier ?",
      sureClosing: "Êtes-vous sûr de vouloir clôturer la position ?",
      pleaseRechargeChoose: "Veuillez choisir le canal de recharge",
      pleaseRechargeInput: "Veuillez entrer le montant de la recharge",
    },
    userText: {
      primaryCertification: "Certification de base",
      advancedCertification: "Certification avancée",
      hasAuth: "Certifié",
      noAuth: "Non certifié",
      auditIng: "En cours de vérification",
      userInfo: "Informations personnelles",
      idCardInfo: "Informations sur la carte d'identité",
      cardInfo: "Informations sur les documents d'identité",
      startBegin: "Commencer maintenant",
      authTip: "Après la certification de base, vous pouvez passer à la certification avancée",
      userName: "Prénom",
      sureName: "Nom",
      cardNumber: "Numéro de document",
      cardType: "Type de document",
      cityName: "Pays / Région",
      addressName: "Adresse",
      idCardType: "Carte d'identité",
      passport: "Passeport",
      drivingLicense: "Permis de conduire",
      idCardFront: "Face de la carte d'identité",
      idCardBack: "Dos de la carte d'identité",
      payPassText: "Mot de passe de paiement",
      loginPassText: "Mot de passe de connexion",
      bindEmailText: "Lier l'adresse e-mail",
      dataStatistics: "Statistiques des données",
      transactionVarieties: "Types de transactions historiques",
      accuracy: "Précision",
      currentPosition: "Position actuelle",
      totalOrder: "Commandes totales",
      profitOrder: "Commandes bénéficiaires",
      lossOrder: "Commandes déficitaires",
      successOrder: "Commandes longues réussies",
      successShortOrder: "Commandes courtes réussies",
      yesterdayTransaction: "État des transactions d'hier",
      profitText: "Bénéfice",
      historyFollow: "Nombre de suiveurs historique",
      fictitiousContractName: "Contrat virtuel de seconde",
      orderRatio: "Ratio des commandes",
      buyNumber: "Nombre d'achats",
      sellNumber: "Nombre de ventes",
      metalsSecondContract: "Contrat de seconde sur devises / métaux précieux",
      virtualCoinContract: "Contrat de cryptomonnaie",
      preciousMetalContracts: "Contrats sur devises / métaux précieux",
      bindDigitalAddress: "Lier l'adresse numérique",
      digitalAddress: "Adresse numérique",
      bindText: "Lier",
      relieveText: "Délier",
      addBankText: "Ajouter un portefeuille numérique",
      bankAddress: "Adresse du portefeuille",
      rechargeCurrency: "Devise de recharge",
      bankRecharge: "Recharge par carte bancaire",
      manualService: "Service de recharge manuel",
    },
    headerText: {
      recharge: "Recharge rapide",
      memberCenter: "Centre des membres",
      switchAccounts: "Changer de compte",
      bankCard: "Portefeuille bancaire",
      investmentStatistics: "Statistiques d'investissement",
      logOut: "Se déconnecter",
      helpCenter: "Centre d'aide",
      aboutSelf: "À propos de nous",
      startBegin: "Guide pour débutants",
      scanText: "Veuillez scanner ce QR code",
      downText: "Télécharger",
      liaison: "Contact",
      customerService: "Service client",
      login: "Connexion / Inscription",
      accountSet: "Paramètres du compte",
    },
    menu: {
      quotation: "Cours",
      transactionType: "Types de transactions",
      transaction: "Transactions",
      currencytransaction: "Transactions de cryptomonnaies",
      fiatTransaction: "Transactions en monnaie fiduciaire",
      contract: "Contrats",
      assets: "Actifs",
      secondContract: "Contrats de seconde",
    },
    rankText: {
      priceIncrease: "Classement des hausses",
      transactionAmount: "Classement des volumes",
      name: "Nom",
      nowPrice: "Prix actuel",
      transactionType: "Type de transaction",
      amount: "Quantité",
      operation: "Opération",
      futures: "Futures",
      priceRate: "Taux de variation",
      emptyText: "Aucune donnée disponible",
    },
    homeFooter: {
      joinUs: "Bienvenue chez NYSEIMF, rejoignez-nous pour construire ensemble l'avenir de NYSEIMF et réaliser ses grandes ambitions",
    },
    market: {
      typeName: "Type",
      digitalCurrency: "Cryptomonnaie",
      nobleMetal: "Métaux précieux",
      internationalFutures: "Futures internationaux",
      currency: "Monnaie",
      foreignExchange: "Devises",
      marketText: "Marché",
      productName: "Produit",
      dealPrice: "Prix de transaction",
      direction: "Direction",
      time: "Heure",
      profitLoss: "Profit/Perte",
      beginPrice: "Prix d'ouverture",
      highestPrice: "Prix le plus élevé",
      lowestPrice: "Prix le plus bas",
      turnover: "Volume",
      closePrice: "Prix de clôture",
      buyMore: "Achat long",
      buyEmpty: "Achat court",
      doMore: "Position longue",
      doLess: "Position courte",
      hasNum: "Position détenue",
      tip: "Choisissez la durée (glissez vers la gauche pour un rendement plus élevé)",
      second: "seconde",
      numberInput: "Montant d'investissement",
      numberInputPlac: "Veuillez entrer le montant d'investissement",
      canUse: "Disponible",
      balance: "Solde",
      serviceCharge: "Frais de service",
      commission: "Ordre au marché",
      commissionText: "Marché",
      fixedPrice: "Ordre limité",
      fixedText: "Limité",
      commissionPlac: "Veuillez entrer le montant de l'ordre",
      area: "Plage",
      price: "Prix",
      volume: "Volume",
      volumePlac: "Veuillez entrer le volume",
      coinCurrency: "Cryptomonnaie",
      currentCommission: "Ordres en cours",
      historyCommission: "Historique des ordres",
      introduction: "Introduction",
      wholeAll: "Tout",
      transactionRecords: "Historique des transactions",
      tradingPairs: "Paires de trading",
      aTurnover: "Volume de transactions",
      newPrice: "Nouveau prix",
      orderNum: "Numéro de commande",
      status: "Statut",
      entrustmentTime: "Date de l'ordre",
      openHouse: "Ouverture de position",
      openHousePrice: "Prix d'ouverture",
      nowText: "Maintenant",
      netWorth: "Valeur nette",
      riskLevel: "Niveau de risque",
      takeProfit: "Prendre le profit",
      profitPlace: "Veuillez entrer le montant du profit",
      stopLoss: "Stop-loss",
      lossPlace: "Veuillez entrer le montant du stop-loss",
      bondMoney: "Garantie",
      fontMoney: "Prix par unité",
      font: "unité",
      buyMoreBtn: "Acheter long (haussier)",
      buyLessBtn: "Vendre court (baissier)",
      recharge: "Recharger",
      rechargeTips: "Détails de la recharge",
      withdraw: "Retirer",
      floatingProfit: "Profit/ Perte flottante (compte réel)",
      accountBalance: "Solde du compte",
      accountChoose: "Choisir le compte",
      accountChoosePlac: "Veuillez choisir le compte",
      freeMargin: "Marge libre",
      usedMargin: "Marge utilisée",
      accountNet: "Valeur nette du compte",
      freezeAmount: "Montant gelé",
      coinAccount: "Compte de cryptomonnaie",
      contractAccount: "Compte de contrat",
      freezeText: "Gelé",
      disassemble: "Converti (USD)",
      transactionPrice: "Prix de transaction",
      openingNum: "Quantité d'ouverture",
      guaranteePrice: "Actif garanti",
      nowPrice: "Prix actuel",
      takeProfitPrice: "Prix de prise de profit",
      stopLossPrice: "Prix de stop-loss",
      hangingPrice: "Prix de suspension",
      historyOrders: "Historique des ordres de transaction",
      typeCurrency: "Type / Monnaie",
      buyMoney: "Montant d'achat",
      sellPrice: "Prix de vente",
      buyPrice: "Prix d'achat",
      profit: "Profit",
      buyTime: "Date d'achat",
      finshTime: "Date de fin",
      sellTime: "Date de vente",
      chainName: "Nom de la chaîne",
      chainChoosePlac: "Choisir la cryptomonnaie",
      chainNumPlac: "Veuillez entrer le nombre",
      xxpectedRechargeTitle: "Montant USDT attendu pour la recharge",
      uploadImgText: "Télécharger une image",
      markPaid: "Marquer comme payé",
      payMoney: "Montant payé",
      payTime: "Date de paiement",
      payStatus: "Statut de paiement",
      withdrawalDetails: "Détails du retrait",
      remark: "Remarque",
      rechargeAmount: "Montant de la recharge",
      withdrawalAmount: "Montant du retrait",
      withdrawalAmountPlac: "Veuillez entrer le montant du retrait",
      withdrawalTips: "Le montant minimum de retrait est de",
      nowNumberError: "Le montant actuel de retrait est inférieur au montant minimum",
      submitBtnText: "Confirmer la soumission",
      orderNumber: "Nombre de commandes",
    },
    order: {
      buyIng: "En cours de transaction",
      closing: "Clôture en cours",
      closingText: "Clôture",
      hasClosed: "Clôturé",
      waitPay: "En attente de paiement",
      rechargeFail: "Échec de la recharge",
      rechargeSuccess: "Recharge réussie",
      callbacking: "Callback en cours",
    },
    "请先完成实名认证": "Veuillez d'abord compléter la certification d'identité",
    "参数错误": "Erreur de paramètre",
    "数据未找到": "Données non trouvées",
    "还未创建账户": "Aucun compte n'a encore été créé",
    "钱包未找到,请先添加钱包": "Portefeuille non trouvé, veuillez d'abord ajouter un portefeuille",
    "资金钱包余额不足": "Solde insuffisant dans le portefeuille de fonds",
    "入金成功": "Versement réussi",
    "项目不存在": "Le projet n'existe pas",
    "最少存入数量为：": "Le montant minimum à déposer est :",
    "success": "Succès",
    "数量不可低于0": "La quantité ne peut pas être inférieure à 0",
    "账户余额不足": "Solde du compte insuffisant",
    "你的冻结余额为0": "Votre solde gelé est de 0",
    "存币成功": "Dépôt de cryptomonnaie réussi",
    "找不到存单": "Impossible de trouver le dépôt",
    "非法操作": "Opération illégale",
    "存单状态异常": "État du dépôt anormal",
    "毁约成功": "Violation de contrat réussie",
    "功能暂不可用": "Fonctionnalité temporairement indisponible",
    "订单不能取消": "La commande ne peut pas être annulée",
    "提取数量超过持有数量": "La quantité de retrait dépasse la quantité détenue",
    "找不到交易对": "Impossible de trouver la paire de trading",
    "帐户已被冻结": "Le compte a été gelé",
    "请求异常": "Anomalie de requête",
    "状态异常": "État anormal",
    "取消失败:": "Échec de l'annulation :",
    "取消成功": "Annulation réussie",
    "pid不能为空": "Le PID ne peut pas être vide",
    "产品不存在": "Le produit n'existe pas",
    "配置错误": "Configuration incorrecte",
    "金额错误": "Montant incorrect",
    "项目状态错误": "État du projet incorrect",
    "暂无数据": "Aucune donnée disponible",
    "文件大小超出": "Taille de fichier dépassée",
    "该文件已存在": "Ce fichier existe déjà",
    "文件类型不对": "Type de fichier incorrect",
    "上传失败": "Échec du téléchargement",
    "订单取消成功": "Annulation de la commande réussie",
    "配置不存在": "Configuration inexistante",
    "您尚未激活此交易对的交易功能": "Vous n'avez pas encore activé la fonction de trading pour cette paire",
    "您的交易当前正在关闭，此时无法继续交易": "Votre transaction est en cours de clôture, il n'est pas possible de continuer à trader à ce moment-là",
    "类型错误": "Erreur de type",
    "此功能当前未打开": "Cette fonctionnalité n'est pas activée actuellement",
    "限价交易不能以低于当前价格的价格出售": "Une transaction limitée ne peut pas être vendue à un prix inférieur au prix actuel",
    "提交成功": "Soumission réussie",
    "此功能系统未打开": "Cette fonctionnalité n'est pas activée dans le système",
    "止盈止损价格不能为0": "Les prix de take-profit et stop-loss ne peuvent pas être de 0",
    "找不到交易记录": "Impossible de trouver l'historique des transactions",
    "买入（多头）止盈价格不能低于开盘价和当前价格": "Le prix de take-profit (achat) ne peut pas être inférieur au prix d'ouverture et au prix actuel",
    "买入（做多）止损价格不能高于开盘价和当前价格": "Le prix de stop-loss (achat) ne peut pas être supérieur au prix d'ouverture et au prix actuel",
    "卖出（卖空）止盈价格不能高于开盘价和当前价格": "Le prix de take-profit (vente) ne peut pas être supérieur au prix d'ouverture et au prix actuel",
    "卖出（卖空）止损价格不能低于开盘价和当前价格": "Le prix de stop-loss (vente) ne peut pas être inférieur au prix d'ouverture et au prix actuel",
    "设置成功": "Configuration réussie",
    "设置失败": "Échec de la configuration",
    "购买方向传输错误": "Erreur de transmission de la direction d'achat",
    "取消成功": "Annulation réussie",
    "取消失败：": "Échec de l'annulation :",
    "取消失败：用户钱包不存在": "Échec de l'annulation : le portefeuille de l'utilisateur n'existe pas",
    "交易记录不存在或已被取消。请刷新并重试": "L'historique des transactions n'existe pas ou a été annulé. Veuillez actualiser et réessayer",
    "找不到钱包，请先添加钱包": "Impossible de trouver le portefeuille, veuillez d'abord ajouter un portefeuille",
    "余额不足，不能小于": "Solde insuffisant, ne peut pas être inférieur à",
    "扣款失败：": "Échec de la déduction :",
    "手续费扣减失败：": "Échec de la déduction des frais :",
    "未找到数据": "Aucune donnée trouvée",
    "未经授权的操作": "Opération non autorisée",
    "交易状态异常，请不要重新提交": "État de transaction anormal, ne resoumettez pas",
    "关闭失败，请重试": "Échec de la clôture, veuillez réessayer",
    "交易异常，无法平仓": "Anomalie de transaction, impossible de clôturer la position",
    "请输入账号": "Veuillez entrer le nom d'utilisateur",
    "请输入密码": "Veuillez entrer le mot de passe",
    "用户不存在": "L'utilisateur n'existe pas",
    "密码错误": "Mot de passe incorrect",
    "您的帐户已被冻结。详情请咨询客服。": "Votre compte a été gelé. Pour plus de détails, veuillez contacter le service client.",
    "密码只能在6到16位之间": "Le mot de passe doit être entre 6 et 16 caractères",
    "验证码错误": "Code de vérification incorrect",
    "用户名已存在": "Le nom d'utilisateur existe déjà",
    "请填写正确的邀请码": "Veuillez entrer le bon code d'invitation",
  };