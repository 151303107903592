export default {
  path: "/innerPage",
  name: "innerPage",
  component: () => import("@/views/innerPage/index.vue"),
  children: [
    // 会员中心
    {
      path: "memberCenter",
      name: "memberCenter",
      component: () => import("@/views/innerPage/memberCenter.vue"),
    },
    // 帮助中心
    {
      path: "heloCenter",
      name: "heloCenter",
      component: () => import("@/views/innerPage/heloCenter.vue"),
    },
  // 账户设置
  {
    path: "accountSettings",
    name: "accountSettings",
    component: () => import("@/views/innerPage/accountSettings.vue"),
  },
  ],
};
