// English
export default {
  loginInfo: {
    loginTitle: "Login",
    registerTitle: "Register",
    loginTip: "Welcome back! Please log in using your email or phone number",
    registerTip: "Please register using your email or phone number",
    userNamePlc: "Please enter your account",
    userPassPlc: "Please enter your password",
    login: "Login",
    register: "Register",
    overtime: "Login timeout, please log in again",
    loginSuccess: "Login successful",
    registerSuccess: "Registration successful",
    userNameTip: "Please enter your account",
    userPassTip: "Please enter your password",
    userOldPassTip: "Please enter your old password",
    userNewPassTip: "Please enter your new password",
    userPassAgainTip: "Please enter your new password again",
    errorPassTip: "Passwords do not match",
    userPassSureTip: "Please enter your password again",
    jumpPageText: "This page requires login to view, please log in",
  },
  tips: {
    loginoutSuccess: "Logout successful",
    tip: "Tip",
    success: "Success",
    error: "Error",
    pleaseSelect: "Please select",
    pleaseEnter: "Please enter",
    pleaseUpload: "Please upload",
    cancelText: "Cancel",
    sureText: "Confirm",
    submitText: "Submit",
    uploadImg: "Please upload the correct image format!",
    uploadImgSize: "Please upload an image smaller than 5M!",
    inputCode: "Enter verification code",
    inputNewEmail: "Enter new email",
    inputSureEmail: "Enter correct email format",
    getCodeText: "Get verification code",
    editText: "Edit",
    viewText: "View",
    reGetCodeText: "Get it again after",
    editPassTip: "Change login password",
    editPayTip: "Change payment password",
    loadingText: "Loading",
    addText: "Add",
    sureRelieveText: "Confirm to unbind?",
    sureClosing: "Confirm to close?",
    pleaseRechargeChoose: "Please select the recharge channel",
    pleaseRechargeInput: "Please enter the recharge amount",
  },
  userText: {
    primaryCertification: "Primary Certification",
    advancedCertification: "Advanced Certification",
    hasAuth: "Certified",
    noAuth: "Not Certified",
    auditIng: "Under Review",
    userInfo: "Personal Information",
    idCardInfo: "ID Card Information",
    cardInfo: "Document Information",
    startBegin: "Start Now",
    authTip: "After primary certification, you can proceed with advanced certification",
    userName: "First Name",
    sureName: "Last Name",
    cardNumber: "Document Number",
    cardType: "Document Type",
    cityName: "Country/Region",
    addressName: "Address",
    idCardType: "ID Card",
    passport: "Passport",
    drivingLicense: "Driver's License",
    idCardFront: "ID Card Front",
    idCardBack: "ID Card Back",
    payPassText: "Payment Password",
    loginPassText: "Login Password",
    bindEmailText: "Bind Email",
    dataStatistics: "Data Statistics",
    transactionVarieties: "Historical Transaction Varieties",
    accuracy: "Accuracy",
    currentPosition: "Current Position",
    totalOrder: "Total Orders",
    profitOrder: "Profit Orders",
    lossOrder: "Loss Orders",
    successOrder: "Successful Long Orders",
    successShortOrder: "Successful Short Orders",
    yesterdayTransaction: "Yesterday's Transaction Status",
    profitText: "Profit",
    historyFollow: "Historical Followers",
    fictitiousContractName: "Cryptocurrency Second Contract",
    orderRatio: "Order Ratio",
    buyNumber: "Buy Orders",
    sellNumber: "Sell Orders",
    metalsSecondContract: "Foreign Exchange/Precious Metals Second Contract",
    virtualCoinContract: "Cryptocurrency Contract",
    preciousMetalContracts: "Foreign Exchange/Precious Metals Contract",
    bindDigitalAddress: "Bind Digital Address",
    digitalAddress: "Digital Address",
    bindText: "Bind",
    relieveText: "Unbind",
    addBankText: "Add Digital Wallet",
    bankAddress: "Wallet Address",
    rechargeCurrency: "Recharge Currency",
    bankRecharge: "Bank Card Recharge",
    manualService: "Manual Recharge Service",
  },
  // Top Navigation
  headerText: {
    recharge: "Quick Recharge",
    // Personal Center
    memberCenter: "Member Center",
    switchAccounts: "Switch Account",
    bankCard: "Bank Card Package",
    investmentStatistics: "Investment Statistics",
    logOut: "Logout",
    // Right Top Menu
    helpCenter: "Help Center",
    aboutSelf: "About Us",
    startBegin: "Newbie Guide",
    // Scan Prompt
    scanText: "Please scan this QR code",
    // Download Prompt
    downText: "Download",
    liaison: "Contact",
    customerService: "Customer Service",
    login: "Login/Register",
    accountSet: "Account Settings",
  },
  // Menu
  menu: {
    quotation: "Market",
    transactionType: "Transaction Type",
    transaction: "Trade",
    currencytransaction: "Currency Trading",
    fiatTransaction: "Fiat Trading",
    contract: "Contract",
    assets: "Assets",
    secondContract: "Second Contract",
  },
  // Rank List
  rankText: {
    priceIncrease: "Price Increase Rank",
    transactionAmount: "Transaction Amount Rank",
    name: "Name",
    nowPrice: "Latest Price",
    transactionType: "Transaction Variety",
    amount: "Volume",
    operation: "Operation",
    futures: "Futures",
    priceRate: "Price Change",
    emptyText: "No Data",
  },
  // Home Footer
  homeFooter: {
    joinUs: "Welcome to NYSEIMF, let's build the blueprint of NYSEIMF together and build the glory of NYSEIMF together",
  },
  // Market
  market: {
    typeName: "Type",
    digitalCurrency: "Digital Currency",
    nobleMetal: "Precious Metal",
    internationalFutures: "International Futures",
    currency: "Currency",
    foreignExchange: "Foreign Exchange",
    marketText: "Market",
    productName: "Product",
    dealPrice: "Deal Price",
    direction: "Direction",
    time: "Time",
    profitLoss: "Profit/Loss",
    beginPrice: "Opening Price",
    highestPrice: "Highest Price",
    lowestPrice: "Lowest Price",
    turnover: "Turnover",
    closePrice: "Closing Price",
    buyMore: "Buy More",
    buyEmpty: "Buy Empty",
    doMore: "Buy",
    doLess: "Sell",
    hasNum: "Position",
    tip: "Select the expiration time (left slide for higher returns)",
    second: "Seconds",
    numberInput: "Investment Amount",
    numberInputPlac: "Please enter the investment amount",
    canUse: "Available",
    balance: "Balance",
    serviceCharge: "Service Fee",
    commission: "Market Order",
    commissionText: "Market",
    fixedPrice: "Limit Order",
    fixedText: "Limit",
    commissionPlac: "Please enter the order amount",
    area: "Range",
    price: "Price",
    volume: "Volume",
    volumePlac: "Please enter the volume",
    coinCurrency: "Currency Trading",
    currentCommission: "Current Order",
    historyCommission: "Historical Order",
    introduction: "Introduction",
    wholeAll: "All",
    transactionRecords: "Transaction Records",
    tradingPairs: "Trading Pairs",
    aTurnover: "Transaction Amount",
    newPrice: "Latest Price",
    orderNum: "Order Number",
    status: "Status",
    entrustmentTime: "Order Time",
    openHouse: "Open Position",
    openHousePrice: "Open Price",
    nowText: "Current",
    netWorth: "Net Worth",
    riskLevel: "Risk Level",
    takeProfit: "Take Profit",
    profitPlace: "Please enter the take profit amount",
    stopLoss: "Stop Loss",
    lossPlace: "Please enter the stop loss amount",
    bondMoney: "Margin",
    fontMoney: "Per Hand Price",
    font: "Hand",
    buyMoreBtn: "Buy Long (Bullish)",
    buyLessBtn: "Sell Short (Bearish)",
    recharge: "Recharge",
    rechargeTips: "Recharge Details",
    withdraw: "Withdraw",
    floatingProfit: "Floating Profit (Real)",
    accountBalance: "Account Balance",
    accountChoose: "Select Account",
    accountChoosePlac: "Please select an account",
    freeMargin: "Available Margin",
    usedMargin: "Used Margin",
    accountNet: "Account Net",
    freezeAmount: "Frozen Amount",
    coinAccount: "Currency Account",
    contractAccount: "Contract Account",
    freezeText: "Frozen",
    disassemble: "Converted (USD)",
    transactionPrice: "Transaction Price",
    openingNum: "Open Volume",
    guaranteePrice: "Guaranteed Asset",
    nowPrice: "Current Price",
    takeProfitPrice: "Take Profit Price",
    stopLossPrice: "Stop Loss Price",
    hangingPrice: "Hanging Price",
    historyOrders: "Historical Transaction Orders",
    typeCurrency: "Type/Currency",
    buyMoney: "Buy Amount",
    sellPrice: "Sell Price",
    buyPrice: "Buy Price",
    profit: "Profit",
    buyTime: "Buy Time",
    finshTime: "Completion Time",
    sellTime: "Sell Time",
    chainName: "Chain Name",
    chainChoosePlac: "Select Currency",
    chainNumPlac: "Please enter the quantity",
    xxpectedRechargeTitle: "Expected Recharge USDT Amount",
    uploadImgText: "Upload Image",
    markPaid: "Mark as Paid",
    payMoney: "Payment Amount",
    payTime: "Payment Time",
    payStatus: "Payment Status",
    withdrawalDetails: "Withdrawal Details",
    remark: "Remark",
    rechargeAmount: "Recharge Amount",
    withdrawalAmount: "Withdrawal Amount",
    withdrawalAmountPlac: "Please enter the withdrawal amount",
    withdrawalTips: "The minimum withdrawal amount is",
    nowNumberError: "The current withdrawal amount is less than the minimum withdrawal amount",
    submitBtnText: "Confirm Submission",
    orderNumber: "Order Number",
  },
  order: {
    buyIng: "Trading",
    closing: "Closing",
    closingText: "Close",
    hasClosed: "Closed",
    waitPay: "Pending Payment",
    rechargeFail: "Recharge Failed",
    rechargeSuccess: "Recharge Successful",
    callbacking: "Callbacking",
  },
  请先完成实名认证: "Please complete real-name authentication first",
  参数错误: "Parameter Error",
  数据未找到: "Data Not Found",
  还未创建账户: "Account Not Created Yet",
  "钱包未找到,请先添加钱包": "Wallet Not Found, Please Add Wallet First",
  资金钱包余额不足: "Insufficient Balance in Wallet",
  入金成功: "Deposit Successful",
  项目不存在: "Project Does Not Exist",
  "最少存入数量为：": "Minimum Deposit Amount is:",
  success: "Success",
  数量不可低于0: "Quantity Cannot Be Less Than 0",
  账户余额不足: "Insufficient Account Balance",
  你的冻结余额为0: "Your Frozen Balance is 0",
  存币成功: "Coin Deposit Successful",
  找不到存单: "Deposit Order Not Found",
  非法操作: "Illegal Operation",
  存单状态异常: "Deposit Order Status Abnormal",
  毁约成功: "Breach Successful",
  功能暂不可用: "Feature Temporarily Unavailable",
  订单不能取消: "Order Cannot Be Cancelled",
  提取数量超过持有数量: "Withdrawal Quantity Exceeds Holding Quantity",
  找不到交易对: "Trading Pair Not Found",
  帐户已被冻结: "Account Has Been Frozen",
  请求异常: "Request Exception",
  状态异常: "Status Exception",
  "取消失败:": "Cancellation Failed:",
  取消成功: "Cancellation Successful",
  pid不能为空: "pid cannot be empty",
  产品不存在: "Product Does Not Exist",
  配置错误: "Configuration Error",
  金额错误: "Amount Error",
  项目状态错误: "Project Status Error",
  暂无数据: "No Data",
  文件大小超出: "File Size Exceeds",
  该文件已存在: "This File Already Exists",
  文件类型不对: "Incorrect File Type",
  上传失败: "Upload Failed",
  订单取消成功: "Order Cancellation Successful",
  配置不存在: "Configuration Does Not Exist",
  您尚未激活此交易对的交易功能: "You Have Not Activated Trading Function for This Trading Pair",
  "您的交易当前正在关闭，此时无法继续交易":
    "Your Transaction Is Currently Closing, You Cannot Continue Trading At This Time",
  类型错误: "Type Error",
  此功能当前未打开: "This Feature Is Currently Closed",
  限价交易不能以低于当前价格的价格出售: "Limit Orders Cannot Be Sold At A Price Lower Than The Current Price",
  提交成功: "Submission Successful",
  此功能系统未打开: "This Feature System Is Not Open",
  止盈止损价格不能为0: "Take Profit/Stop Loss Price Cannot Be 0",
  找不到交易记录: "Transaction Record Not Found",
  "买入（多头）止盈价格不能低于开盘价和当前价格":
    "Buy (Long) Take Profit Price Cannot Be Lower Than Opening Price and Current Price",
  "买入（做多）止损价格不能高于开盘价和当前价格":
    "Buy (Long) Stop Loss Price Cannot Be Higher Than Opening Price and Current Price",
  "卖出（卖空）止盈价格不能高于开盘价和当前价格":
    "Sell (Short) Take Profit Price Cannot Be Higher Than Opening Price and Current Price",
  "卖出（卖空）止损价格不能低于开盘价和当前价格":
    "Sell (Short) Stop Loss Price Cannot Be Lower Than Opening Price and Current Price",
  设置成功: "Setting Successful",
  设置失败: "Setting Failed",
  购买方向传输错误: "Purchase Direction Transmission Error",
  取消成功: "Cancellation Successful",
  "取消失败：": "Cancellation Failed:",
  "取消失败：用户钱包不存在": "Cancellation Failed: User Wallet Does Not Exist",
  "交易记录不存在或已被取消。请刷新并重试":
    "Transaction Record Does Not Exist or Has Been Cancelled. Please Refresh and Try Again",
  "找不到钱包，请先添加钱包": "Wallet Not Found, Please Add Wallet First",
  "余额不足，不能小于": "Insufficient Balance, Cannot Be Less Than",
  "扣款失败：": "Deduction Failed:",
  "手续费扣减失败：": "Service Fee Deduction Failed:",
  未找到数据: "Data Not Found",
  未经授权的操作: "Unauthorized Operation",
  "交易状态异常，请不要重新提交": "Transaction Status Abnormal, Please Do Not Resubmit",
  "关闭失败，请重试": "Close Failed, Please Try Again",
  "交易异常，无法平仓": "Transaction Abnormal, Cannot Close Position",
  请输入账号: "Please enter your account",
  请输入密码: "Please enter your password",
  用户不存在: "User Does Not Exist",
  密码错误: "Password Error",
  "您的帐户已被冻结。详情请咨询客服。": "Your Account Has Been Frozen. Please Contact Customer Service For Details.",
  密码只能在6到16位之间: "Password Must Be Between 6 and 16 Characters",
  验证码错误: "Verification Code Error",
  用户名已存在: "Username Already Exists",
  请填写正确的邀请码: "Please Enter the Correct Invitation Code",
};
