import service from "@/http";
// 登录
export const login = (data) => {
  return service({
    url: "/user/login",
    method: "post",
    data: data,
  });
};

// 注册
export const register = (data) => {
  return service({
    url: "/user/register",
    method: "post",
    data: data,
  });
};
// 获取用户信息
export const getUserInfo = (data) => {
  return service({
    url: "/user/info",
    method: "get",
    params: data,
  });
};

// 获取用户钱包
export const getWallet = (data) => {
  return service({
    url: "/wallet/list",
    method: "post",
    data: data,
  });
};

// 获取用户认证信息
export const getrealinfo = (data) => {
  return service({
    url: "/user/getrealinfo",
    method: "get",
    params: data,
  });
};

// 认证
export const real_name = (data) => {
  return service({
    url: "/user/real_name",
    method: "post",
    data: data,
  });
};

// 投资统计
export const getdatastatistics = (data) => {
  return service({
    url: "/user/datastatistics",
    method: "get",
    params: data,
  });
};

// 银行卡
export const getcash_info = (data) => {
  return service({
    url: "/user/cash_info",
    method: "post",
    data: data,
  });
};

// 银行卡 - 保存
export const cash_save = (data) => {
  return service({
    url: "/user/cash_save",
    method: "post",
    data: data,
  });
};

// 银行卡 - 解绑
export const cash_del = (data) => {
  return service({
    url: "/user/cash_del",
    method: "post",
    data: data,
  });
};
// 提现
export const outBank = (data)=> {
  return service({
    url: "/wallet/out",
    method: "post",
    data: data,
  });
}

// 提现明细
export const cashlistlog = (data) => {
  return service({
    url: "/cashlist/log",
    method: "get",
    params: data,
  });
};

// 获取客服
export const getkf = (data) => {
  return service({
    url: "/news/getkf",
    method: "get",
    params: data,
  });
};

// 获取地址
export const get_in_address = (data) => {
  return service({
    url: "/wallet/get_in_address",
    method: "post",
    data: data,
  });
};

// 充值明细
export const getRechargelog = (data) => {
  return service({
    url: "/chargelist/log",
    method: "get",
    params: data,
  });
};

// 充值
export const charge_req = (data) => {
  return service({
    url: "/wallet/charge_req",
    method: "post",
    data: data,
  });
};