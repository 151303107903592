import axios from "axios";
import { Message } from "element-ui";
import { getToken } from "@/utils/auth";
import { tansParams } from "@/utils/common";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
// 是否显示重新登录
export let isRelogin = { show: false };
import { useLang } from "@/hooks/useLang";
const { lang } = useLang();
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: `${process.env.VUE_APP_BASE_API}/api`,
  // baseURL: process.env.VUE_APP_BASE_API + "/api",
  // 超时
  timeout: 10000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    if (getToken()) {
      config.headers["authorization"] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // get请求映射params参数
    if (config.method === "get") {
      config.params = { ...config.params, lang: lang.value };
    }
    if (config.method === "get" && config.params) {
      let url = config.url + "?" + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(async (res) => {
  // 未设置状态码则默认成功状态
  const status = res.status;
  const code = res.data.code || 200;
  const type = res.data.type;
  if (status == 200) {
    if (type == "ok" || code == 1) {
      // Message({
      //   message: i18n.t(res.data.message),
      //   type: "success",
      //   duration: 3 * 1000,
      // });
      return Promise.resolve(res.data);
    } else if (type == "999") {
      Message.error(i18n.t(res.data.message));
      await store.dispatch("user/logOut");
      router.push("/login");
    } else {
      Message.error(i18n.t(res.data.message));
      return Promise.reject();
    }
  } else if (code == 404) {
    Message({ message: "404 Not Found", type: "error", duration: 3 * 1000 });
    return Promise.reject();
  }
});

export default service;
