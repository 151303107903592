import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "@/store/index";
import i18n from "./i18n";
import "./permission";
// import Socket from "./utils/socket/socket";
// import Socketio from "./utils/socket/socket.io";

Vue.use(ElementUI);
Vue.use({
  i18n: (key, value) => i18n.t(key, value),
});
Vue.config.productionTip = false;
const socket_api = 'ws://nysea.top';
Vue.prototype.$socket_api = socket_api;
// Vue.prototype.$socket = Socket;
// Vue.prototype.$Socketio = Socketio;
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
