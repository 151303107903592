import router from "@/router";
import { Message } from "element-ui";
import { getToken } from "./utils/auth";
import store from "./store";
import i18n from "./i18n";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style\
NProgress.configure({ showSpinner: false }); // NProgress Configuration
const shouldLogin = [
  "/assetsPage",
  '/marketDetail',
  '/assetsRecharge',
  '/marketContract',
  '/marketCurrency'
];
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  //判断本身是否登录 跳转过来有code 本身没有登录的话就调免登录接口
  const flag = shouldLogin.includes(to.path);
  if (flag && !getToken()) {
    Message.error(i18n.t('loginInfo.jumpPageText'));
    next("/login");
  } else {
    next();
  }
  NProgress.done();
});
router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
