<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script setup>
	const checkIsmobile = () => {
		let userAgent = navigator.userAgent.toLowerCase();
		if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(userAgent)) {
			location.href = 'https://nysea.top/h5/#/'
			return false
		}
	}
	checkIsmobile()
</script>
<style lang="scss"></style>