export default {
    loginInfo: {
      loginTitle: "Inicio de sesión",
      registerTitle: "Registro de cuenta",
      loginTip: "¡Bienvenido de nuevo! Inicie sesión con su correo electrónico o número de teléfono",
      registerTip: "Regístrese con su correo electrónico o número de teléfono",
      userNamePlc: "Por favor, introduzca el nombre de usuario",
      userPassPlc: "Por favor, introduzca la contraseña",
      login: "Iniciar sesión",
      register: "Registrarse",
      overtime: "Tiempo de espera agotado, por favor inicie sesión nuevamente",
      loginSuccess: "Inicio de sesión exitoso",
      registerSuccess: "Registro exitoso",
      userNameTip: "Por favor, introduzca el nombre de usuario",
      userPassTip: "Por favor, introduzca la contraseña",
      userOldPassTip: "Por favor, introduzca la antigua contraseña",
      userNewPassTip: "Por favor, introduzca la nueva contraseña",
      userPassAgainTip: "Por favor, vuelva a introducir la nueva contraseña",
      errorPassTip: "Las contraseñas no coinciden",
      userPassSureTip: "Por favor, confirme la contraseña",
      jumpPageText: "Esta página requiere inicio de sesión, por favor inicie sesión",
    },
    tips: {
      loginoutSuccess: "Cierre de sesión exitoso",
      tip: "Aviso",
      success: "Éxito",
      error: "Error",
      pleaseSelect: "Por favor, seleccione",
      pleaseEnter: "Por favor, introduzca",
      pleaseUpload: "Por favor, suba",
      cancelText: "Cancelar",
      sureText: "Aceptar",
      submitText: "Enviar",
      uploadImg: "¡Suba un formato de imagen correcto!",
      uploadImgSize: "¡Suba una imagen menor a 5MB!",
      inputCode: "Introduzca el código de verificación",
      inputNewEmail: "Introduzca el nuevo correo electrónico",
      inputSureEmail: "Introduzca un correo electrónico en el formato correcto",
      getCodeText: "Obtener código de verificación",
      editText: "Modificar",
      viewText: "Ver",
      reGetCodeText: "reobtener después",
      editPassTip: "Modificar la contraseña de inicio de sesión",
      editPayTip: "Modificar la contraseña de pago",
      loadingText: "Cargando",
      addText: "Agregar",
      sureRelieveText: "¿Está seguro de que desea desvincular?",
      sureClosing: "¿Está seguro de que desea cerrar la posición?",
      pleaseRechargeChoose: "Por favor, elija el canal de recarga",
      pleaseRechargeInput: "Por favor, introduzca la cantidad de recarga",
    },
    // 其他部分...
    // 请继续查看完整翻译
    // ...
    // 最后一部分
    order: {
      buyIng: "En transacción",
      closing: "Cerrando posición",
      closingText: "Cerrar posición",
      hasClosed: "Posición cerrada",
      waitPay: "Esperando pago",
      rechargeFail: "Recarga fallida",
      rechargeSuccess: "Recarga exitosa",
      callbacking: "Llamada de retorno en progreso",
    },
    请先完成实名认证: "Por favor, complete la autenticación de identidad",
    参数错误: "Error de parámetro",
    数据未找到: "Datos no encontrados",
    还未创建账户: "Aún no se ha creado una cuenta",
    "钱包未找到,请先添加钱包": "Billetera no encontrada, por favor añada una billetera primero",
    资金钱包余额不足: "Saldo insuficiente en la billetera de fondos",
    入金成功: "Depósito exitoso",
    项目不存在: "El proyecto no existe",
    "最少存入数量为：": "La cantidad mínima para depositar es:",
    success: "Éxito",
    数量不可低于0: "La cantidad no puede ser inferior a 0",
    账户余额不足: "Saldo de la cuenta insuficiente",
    你的冻结余额为0: "Tu saldo congelado es 0",
    存币成功: "Depósito de monedas exitoso",
    找不到存单: "No se puede encontrar el depósito",
    非法操作: "Operación ilegal",
    存单状态异常: "Estado del depósito anormal",
    毁约成功: "Rescisión de contrato exitosa",
    功能暂不可用: "Función temporalmente no disponible",
    订单不能取消: "El pedido no puede ser cancelado",
    提取数量超过持有数量: "La cantidad de retiro excede la cantidad en posesión",
    找不到交易对: "No se puede encontrar el par de trading",
    帐户已被冻结: "La cuenta ha sido congelada",
    请求异常: "Solicitud anormal",
    状态异常: "Estado anormal",
    "取消失败:": "Fallo al cancelar:",
    取消成功: "Cancelación exitosa",
    pid不能为空: "El PID no puede estar vacío",
    产品不存在: "El producto no existe",
    配置错误: "Configuración incorrecta",
    金额错误: "Monto incorrecto",
    项目状态错误: "Estado del proyecto incorrecto",
    暂无数据: "Sin datos por el momento",
    文件大小超出: "Tamaño de archivo excedido",
    该文件已存在: "El archivo ya existe",
    文件类型不对: "Tipo de archivo incorrecto",
    上传失败: "Fallo al subir",
    订单取消成功: "Pedido cancelado exitosamente",
    配置不存在: "Configuración no existente",
    您尚未激活此交易对的交易功能: "Aún no ha activado la función de trading para este par",
    "您的交易当前正在关闭，此时无法继续交易":
      "Su operación está siendo cerrada, no se puede continuar con el trading en este momento",
    类型错误: "Error de tipo",
    此功能当前未打开: "Esta función no está habilitada actualmente",
    限价交易不能以低于当前价格的价格出售: "Una orden límite no puede venderse a un precio inferior al actual",
    提交成功: "Envío exitoso",
    此功能系统未打开: "Esta función no está habilitada en el sistema",
    止盈止损价格不能为0: "Los precios de toma de ganancias y stop loss no pueden ser 0",
    找不到交易记录: "No se puede encontrar el registro de transacciones",
    "买入（多头）止盈价格不能低于开盘价和当前价格":
      "El precio de toma de ganancias (compra) no puede ser inferior al precio de apertura y al precio actual",
    "买入（做多）止损价格不能高于开盘价和当前价格":
      "El precio de stop loss (compra) no puede ser superior al precio de apertura y al precio actual",
    "卖出（卖空）止盈价格不能高于开盘价和当前价格":
      "El precio de toma de ganancias (venta) no puede ser superior al precio de apertura y al precio actual",
    "卖出（卖空）止损价格不能低于开盘价和当前价格":
      "El precio de stop loss (venta) no puede ser inferior al precio de apertura y al precio actual",
    设置成功: "Configuración exitosa",
    设置失败: "Fallo en la configuración",
    购买方向传输错误: "Error en la transmisión de la dirección de compra",
    取消成功: "Cancelación exitosa",
    "取消失败：": "Fallo al cancelar:",
    "取消失败：用户钱包不存在": "Fallo al cancelar: La billetera del usuario no existe",
    "交易记录不存在或已被取消。请刷新并重试":
      "El registro de transacciones no existe o ha sido cancelado. Por favor, refresque e intente de nuevo",
    "找不到钱包，请先添加钱包": "No se encuentra la billetera, por favor añada una billetera primero",
    "余额不足，不能小于": "Saldo insuficiente, no puede ser menor a",
    "扣款失败：": "Fallo en la deducción de fondos:",
    "手续费扣减失败：": "Fallo en la deducción de las tarifas de servicio:",
    未找到数据: "Datos no encontrados",
    未经授权的操作: "Operación no autorizada",
    "交易状态异常，请不要重新提交": "Estado de transacción anormal, no vuelva a enviar",
    "关闭失败，请重试": "Fallo al cerrar, por favor intente de nuevo",
    "交易异常，无法平仓": "Anomalía en la transacción, no se puede cerrar la posición",
    请输入账号: "Por favor, introduzca el nombre de usuario",
    请输入密码: "Por favor, introduzca la contraseña",
    用户不存在: "Usuario no existe",
    密码错误: "Contraseña incorrecta",
    "您的帐户已被冻结。详情请咨询客服。": "Su cuenta ha sido congelada. Para más detalles, póngase en contacto con el servicio al cliente.",
    密码只能在6到16位之间: "La contraseña debe tener entre 6 y 16 caracteres",
    验证码错误: "Código de verificación incorrecto",
    用户名已存在: "El nombre de usuario ya existe",
    请填写正确的邀请码: "Por favor, introduzca el código de invitación correcto",
  };