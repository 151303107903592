// 日本
export default {
    loginInfo: {
      loginTitle: "アカウントログイン",
      registerTitle: "アカウント登録",
      loginTip: "お帰りなさい！メールまたは電話番号でログインしてください",
      registerTip: "メールまたは電話番号で登録してください",
      userNamePlc: "ユーザー名を入力してください",
      userPassPlc: "パスワードを入力してください",
      login: "ログイン",
      register: "登録",
      overtime: "セッションがタイムアウトしました、再度ログインしてください",
      loginSuccess: "ログイン成功",
      registerSuccess: "登録成功",
      userNameTip: "ユーザー名を入力してください",
      userPassTip: "パスワードを入力してください",
      userOldPassTip: "古いパスワードを入力してください",
      userNewPassTip: "新しいパスワードを入力してください",
      userPassAgainTip: "新しいパスワードを再確認してください",
      errorPassTip: "パスワードが一致しません",
      userPassSureTip: "パスワードを確認してください",
      jumpPageText: "このページはログインが必要です、ログインしてください",
    },
    tips: {
      loginoutSuccess: "ログアウト成功",
      tip: "ヒント",
      success: "成功",
      error: "失敗",
      pleaseSelect: "選択してください",
      pleaseEnter: "入力してください",
      pleaseUpload: "アップロードしてください",
      cancelText: "キャンセル",
      sureText: "確認",
      submitText: "提出",
      uploadImg: "正しい画像形式をアップロードしてください！",
      uploadImgSize: "5MB以下の画像をアップロードしてください！",
      inputCode: "確認コードを入力してください",
      inputNewEmail: "新しいメールアドレスを入力してください",
      inputSureEmail: "正しいメールアドレス形式で入力してください",
      getCodeText: "確認コードを取得",
      editText: "編集",
      viewText: "表示",
      reGetCodeText: "後でもう一度試す",
      editPassTip: "ログインパスワードを変更する",
      editPayTip: "支払いパスワードを変更する",
      loadingText: "読み込み中",
      addText: "追加",
      sureRelieveText: "本当に解除しますか？",
      sureClosing: "本当にポジションをクローズしますか？",
      pleaseRechargeChoose: "チャージチャネルを選択してください",
      pleaseRechargeInput: "チャージ金額を入力してください",
    },
    userText: {
      primaryCertification: "基本認証",
      advancedCertification: "高度認証",
      hasAuth: "認証済み",
      noAuth: "未認証",
      auditIng: "審査中",
      userInfo: "個人情報",
      idCardInfo: "身分証明書情報",
      cardInfo: "身分証明文書情報",
      startBegin: "今すぐ開始",
      authTip: "基本認証後に高度認証に進むことができます",
      userName: "名前",
      sureName: "姓",
      cardNumber: "証明書番号",
      cardType: "証明書タイプ",
      cityName: "国/地域",
      addressName: "住所",
      idCardType: "身分証明書",
      passport: "パスポート",
      drivingLicense: "運転免許証",
      idCardFront: "身分証明書前面",
      idCardBack: "身分証明書背面",
      payPassText: "支払いパスワード",
      loginPassText: "ログインパスワード",
      bindEmailText: "メールアドレスを紐づける",
      dataStatistics: "データ統計",
      transactionVarieties: "取引履歴の種類",
      accuracy: "精度",
      currentPosition: "現在の位置",
      totalOrder: "合計注文数",
      profitOrder: "利益のある注文",
      lossOrder: "損失のある注文",
      successOrder: "成功した買い注文",
      successShortOrder: "成功した売り注文",
      yesterdayTransaction: "昨日の取引状況",
      profitText: "利益",
      historyFollow: "過去のフォロワー数",
      fictitiousContractName: "秒単位の仮想契約",
      orderRatio: "注文比率",
      buyNumber: "買い注文数",
      sellNumber: "売り注文数",
      metalsSecondContract: "為替/貴金属の秒単位契約",
      virtualCoinContract: "仮想通貨契約",
      preciousMetalContracts: "為替/貴金属契約",
      bindDigitalAddress: "デジタルアドレスを紐づける",
      digitalAddress: "デジタルアドレス",
      bindText: "紐づけ",
      relieveText: "解除",
      addBankText: "デジタルウォレットを追加",
      bankAddress: "ウォレットアドレス",
      rechargeCurrency: "チャージ通貨",
      bankRecharge: "銀行カードでのチャージ",
      manualService: "手動チャージサービス",
    },
    headerText: {
      recharge: "高速チャージ",
      memberCenter: "メンバーセンター",
      switchAccounts: "アカウント切り替え",
      bankCard: "銀行口座",
      investmentStatistics: "投資統計",
      logOut: "ログアウト",
      helpCenter: "ヘルプセンター",
      aboutSelf: "私たちについて",
      startBegin: "初心者ガイド",
      scanText: "このQRコードをスキャンしてください",
      downText: "ダウンロード",
      liaison: "連絡先",
      customerService: "カスタマーサービス",
      login: "ログイン/登録",
      accountSet: "アカウント設定",
    },
    menu: {
      quotation: "引用",
      transactionType: "取引タイプ",
      transaction: "取引",
      currencytransaction: "暗号通貨取引",
      fiatTransaction: "法定通貨取引",
      contract: "契約",
      assets: "資産",
      secondContract: "秒単位契約",
    },
    rankText: {
      priceIncrease: "価格上昇ランキング",
      transactionAmount: "取引量ランキング",
      name: "名称",
      nowPrice: "現在の価格",
      transactionType: "取引タイプ",
      amount: "数量",
      operation: "操作",
      futures: "先物",
      priceRate: "価格変動率",
      emptyText: "利用可能なデータはありません",
    },
    homeFooter: {
      joinUs: "NYSEIMFへようこそ、一緒にNYSEIMFの未来を築き、大きな目標を達成しましょう",
    },
    market: {
      typeName: "タイプ",
      digitalCurrency: "暗号通貨",
      nobleMetal: "貴金属",
      internationalFutures: "国際先物",
      currency: "通貨",
      foreignExchange: "外貨",
      marketText: "市場",
      productName: "商品",
      dealPrice: "取引価格",
      direction: "方向",
      time: "時間",
      profitLoss: "利益/損失",
      beginPrice: "開始価格",
      highestPrice: "最高価格",
      lowestPrice: "最安価格",
      turnover: "売買高",
      closePrice: "終値",
      buyMore: "買い（ロング）",
      buyEmpty: "売り（ショート）",
      doMore: "ロングポジション",
      doLess: "ショートポジション",
      hasNum: "保有ポジション",
      tip: "期間を選んでください (左にスライドするとリターンが高くなります)",
      second: "秒",
      numberInput: "投資金額",
      numberInputPlac: "投資金額を入力してください",
      canUse: "使用可能",
      balance: "残高",
      serviceCharge: "サービス料",
      commission: "マーケットオーダー",
      commissionText: "マーケット",
      fixedPrice: "リミットオーダー",
      fixedText: "リミット",
      commissionPlac: "オーダーサイズを入力してください",
      area: "範囲",
      price: "価格",
      volume: "ボリューム",
      volumePlac: "ボリュームを入力してください",
      coinCurrency: "暗号通貨",
      currentCommission: "現在のオーダー",
      historyCommission: "オーダーヒストリー",
      introduction: "紹介",
      wholeAll: "すべて",
      transactionRecords: "取引記録",
      tradingPairs: "取引ペア",
      aTurnover: "取引量",
      newPrice: "新価格",
      orderNum: "注文番号",
      status: "ステータス",
      entrustmentTime: "委託日時",
      openHouse: "ポジションオープン",
      openHousePrice: "オープン価格",
      nowText: "現在",
      netWorth: "純資産",
      riskLevel: "リスクレベル",
      takeProfit: "利確",
      profitPlace: "利確金額を入力してください",
      stopLoss: "損切り",
      lossPlace: "損切り金額を入力してください",
      bondMoney: "担保",
      fontMoney: "単価",
      font: "単位",
      buyMoreBtn: "買い（ロング）",
      buyLessBtn: "売り（ショート）",
      recharge: "チャージ",
      rechargeTips: "チャージ詳細",
      withdraw: "出金",
      floatingProfit: "浮動利益/損失 (実際のアカウント)",
      accountBalance: "アカウント残高",
      accountChoose: "アカウント選択",
      accountChoosePlac: "アカウントを選択してください",
      freeMargin: "フリー・マージン",
      usedMargin: "使用中のマージン",
      accountNet: "アカウント純資産",
      freezeAmount: "凍結金額",
      coinAccount: "暗号通貨アカウント",
      contractAccount: "契約アカウント",
      freezeText: "凍結",
      disassemble: "換算 (USD)",
      transactionPrice: "取引価格",
      openingNum: "開設数",
      guaranteePrice: "担保価格",
      nowPrice: "現在の価格",
      takeProfitPrice: "利確価格",
      stopLossPrice: "損切り価格",
      hangingPrice: "保留価格",
      historyOrders: "取引履歴",
      typeCurrency: "タイプ/通貨",
      buyMoney: "購入金額",
      sellPrice: "売却価格",
      buyPrice: "購入価格",
      profit: "利益",
      buyTime: "購入日時",
      finshTime: "完了日時",
      sellTime: "売却日時",
      chainName: "チェーン名",
      chainChoosePlac: "暗号通貨を選択してください",
      chainNumPlac: "数量を入力してください",
      xxpectedRechargeTitle: "期待されるUSDTチャージ金額",
      uploadImgText: "画像をアップロード",
      markPaid: "支払済みとしてマーク",
      payMoney: "支払金額",
      payTime: "支払日時",
      payStatus: "支払ステータス",
      withdrawalDetails: "出金詳細",
      remark: "備考",
      rechargeAmount: "チャージ金額",
      withdrawalAmount: "出金金額",
      withdrawalAmountPlac: "出金金額を入力してください",
      withdrawalTips: "最低出金額は",
      nowNumberError: "現在の出金額は最小出金額以下です",
      submitBtnText: "提出を確認",
      orderNumber: "注文数",
    },
    order: {
      buyIng: "取引中",
      closing: "クローズ中",
      closingText: "クローズ",
      hasClosed: "クローズ済み",
      waitPay: "支払い待ち",
      rechargeFail: "チャージ失敗",
      rechargeSuccess: "チャージ成功",
      callbacking: "コールバック中",
    },
    "请先完成实名认证": "まず実名認証を完了してください",
    "参数错误": "パラメータエラー",
    "数据未找到": "データが見つかりません",
    "还未创建账户": "アカウントはまだ作成されていません",
    "钱包未找到,请先添加钱包": "ウォレットが見つかりません、まずはウォレットを追加してください",
    "资金钱包余额不足": "資金ウォレットの残高が不足しています",
    "入金成功": "入金成功",
    "项目不存在": "プロジェクトが存在しません",
    "最少存入数量为：": "最低預入数量は次の通りです：",
    "success": "成功",
    "数量不可低于0": "数量は0より小さくすることはできません",
    "账户余额不足": "アカウント残高が不足しています",
    "你的冻结余额为0": "あなたの凍結残高は0です",
    "存币成功": "コイン預入成功",
    "找不到存单": "預入伝票が見つかりません",
    "非法操作": "不正な操作",
    "存单状态异常": "預入伝票の状態が異常です",
    "毁约成功": "契約破棄成功",
    "功能暂不可用": "機能は一時的に利用できません",
    "订单不能取消": "注文はキャンセルできません",
    "提取数量超过持有数量": "引き出し数量が所有数量を超えています",
    "找不到交易对": "取引ペアが見つかりません",
    "帐户已被冻结": "アカウントは凍結されています",
    "请求异常": "リクエスト異常",
    "状态异常": "状態異常",
    "取消失败:": "キャンセル失敗：",
    "取消成功": "キャンセル成功",
    "pid不能为空": "PIDは空にすることはできません",
    "产品不存在": "製品が存在しません",
    "配置错误": "設定エラー",
    "金额错误": "金額エラー",
    "项目状态错误": "プロジェクトの状態エラー",
    "暂无数据": "現在データはありません",
    "文件大小超出": "ファイルサイズが制限を超えています",
    "该文件已存在": "そのファイルは既に存在しています",
    "文件类型不对": "ファイルタイプが間違っています",
    "上传失败": "アップロード失敗",
    "订单取消成功": "注文キャンセル成功",
    "配置不存在": "設定が存在しません",
    "您尚未激活此交易对的交易功能": "あなたはまだこの取引ペアの取引機能を有効にしていません",
    "您的交易当前正在关闭，此时无法继续交易": "あなたの取引は現在クローズ中であり、これ以上取引を続けることはできません",
    "类型错误": "タイプエラー",
    "此功能当前未打开": "この機能は現在利用できません",
    "限价交易不能以低于当前价格的价格出售": "指値取引では現在の価格よりも低い価格で売ることはできません",
    "提交成功": "提出成功",
    "此功能系统未打开": "この機能はシステムで利用できません",
    "止盈止损价格不能为0": "利確と損切りの価格は0にはできません",
    "找不到交易记录": "取引記録が見つかりません",
    "买入（多头）止盈价格不能低于开盘价和当前价格": "買い（ロング）の利確価格は始値および現在価格よりも低くすることはできません",
    "买入（做多）止损价格不能高于开盘价和当前价格": "買い（ロング）の損切り価格は始値および現在価格よりも高くすることはできません",
    "卖出（卖空）止盈价格不能高于开盘价和当前价格": "売り（ショート）の利確価格は始値および現在価格よりも高くすることはできません",
    "卖出（卖空）止损价格不能低于开盘价和当前价格": "売り（ショート）の損切り価格は始値および現在価格よりも低くすることはできません",
    "设置成功": "設定成功",
    "设置失败": "設定失敗",
    "购买方向传输错误": "購入方向の送信エラー",
    "取消成功": "キャンセル成功",
    "取消失败：": "キャンセル失敗：",
    "取消失败：用户钱包不存在": "キャンセル失敗：ユーザーウォレットが存在しません",
    "交易记录不存在或已被取消。请刷新并重试": "取引記録は存在しないか、すでにキャンセルされています。ページを更新してから再度お試しください",
    "找不到钱包，请先添加钱包": "ウォレットが見つかりません、まずはウォレットを追加してください",
    "余额不足，不能小于": "残高が不足しています、以下の金額よりも少なくすることはできません：",
    "扣款失败：": "引き落とし失敗：",
    "手续费扣减失败：": "手数料控除失敗：",
    "未找到数据": "データが見つかりません",
    "未经授权的操作": "認証されていない操作",
    "交易状态异常，请不要重新提交": "取引状態が異常です、再度提出しないでください",
    "关闭失败，请重试": "クローズ失敗、再度お試しください",
    "交易异常，无法平仓": "取引異常、ポジションをクローズできません",
    "请输入账号": "アカウント名を入力してください",
    "请输入密码": "パスワードを入力してください",
    "用户不存在": "ユーザーが存在しません",
    "密码错误": "パスワードが間違っています",
    "您的帐户已被冻结。详情请咨询客服。": "あなたのアカウントは凍結されています。詳細についてはカスタマーサービスにお問い合わせください。",
    "密码只能在6到16位之间": "パスワードは6文字から16文字の間に設定してください",
    "验证码错误": "確認コードが間違っています",
    "用户名已存在": "ユーザー名は既に存在しています",
    "请填写正确的邀请码": "正しい招待コードを入力してください",
  };