<template>
  <div class="headerTopRight flex">
    <a class="rechargeBox statusItem" @click="jumpPage('/assetsRecharge')">
      <img
        src="../../assets/images/common/top-up.svg"
        style="
          width: 26px;
          height: 26px;
          vertical-align: middle;
          margin-right: 5px;
        "
        alt=""
      />
      <span>{{ $t("headerText.recharge") }}</span>
    </a>
    <template v-if="isLogin">
      <!-- 个人中心 -->
      <el-dropdown
        style="display: flex; align-items: center"
        @command="changeMenu"
      >
        <span class="el-dropdown-link text-color p-10 cur-p statusItem">
          <i class="el-icon-s-custom" style="font-size: 16px"></i>
          <i class="el-icon-caret-bottom el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :key="1" :command="1">
            <span>{{ $t("headerText.memberCenter") }}</span>
          </el-dropdown-item>
          <el-dropdown-item :key="2" :command="2">
            <span>{{ $t("headerText.switchAccounts") }}</span>
          </el-dropdown-item>
          <el-dropdown-item :key="3" :command="3">
            <span>{{ $t("headerText.bankCard") }}</span>
          </el-dropdown-item>
          <el-dropdown-item :key="4" :command="4">
            <span>{{ $t("headerText.investmentStatistics") }}</span>
          </el-dropdown-item>
          <el-dropdown-item :key="5" :command="5">
            <span>{{ $t("headerText.logOut") }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- 小菜单 -->
      <el-dropdown
        style="display: flex; align-items: center"
        @command="changeUserMenu"
      >
        <span class="el-dropdown-link p-10 cur-p statusItem">
          <i class="el-icon-menu" style="font-size: 16px"></i>
          <i class="el-icon-caret-bottom el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :key="1" :command="1">
            <span>{{ $t("headerText.helpCenter") }}</span>
          </el-dropdown-item>
          <el-dropdown-item :key="2" :command="2">
            <span>{{ $t("headerText.aboutSelf") }}</span>
          </el-dropdown-item>
          <!-- <el-dropdown-item :key="3" :command="3">
            <span>{{ $t("headerText.startBegin") }}</span>
          </el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
    </template>
    <template v-else>
      <div class="statusItem cur-p" @click="jumpPage('/login')">
        <span>{{ $t("headerText.login") }}</span>
      </div>
    </template>
    <div class="statusItem cur-p">
      <el-popover
        placement="bottom"
        width="100"
        trigger="hover"
        style="display: flex; align-items: center"
      >
        <div class="flex-col ali-cen">
          <span>{{ $t("headerText.scanText") }}</span>
          <img
            src="../../assets/images/downImg.png"
            style="width: 100px; height: 100px"
          />
        </div>
        <span slot="reference">{{ $t("headerText.downText") }}</span>
      </el-popover>
    </div>
    <el-dropdown
      trigger="click"
      @command="changeLang"
      style="display: flex; align-items: center"
    >
      <span class="el-dropdown-link p-10 cur-p statusItem">
        <img
          :src="getLangObject.src"
          style="
            width: 20px;
            height: 20px;
            vertical-align: middle;
            margin-right: 5px;
          "
        />
        {{ getLangObject.label
        }}<i class="el-icon-caret-bottom el-icon--right"></i>
      </span>
      <!-- 语言切换 -->
      <el-dropdown-menu slot="dropdown">
        <template v-for="item in langList">
          <el-dropdown-item :key="item.value" :command="item.value"
            ><img
              :src="item.src"
              style="
                width: 20px;
                height: 20px;
                vertical-align: middle;
                margin-right: 5px;
              "
            />
            <span>{{ item.label }}</span>
          </el-dropdown-item>
        </template>
      </el-dropdown-menu>
    </el-dropdown>
    <login v-if="showLogin" @close="closeLogin"></login>
  </div>
</template>
<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import login from "@/views/login.vue";
import { removeToken, removeAllCookie } from "@/utils/auth";
import { useRouter } from "@/utils/vueApi";
import { Message } from "element-ui";
import { useUser } from "@/hooks/useUser";
import { useStore } from "@/utils/vueApi";
import { useLang } from "@/hooks/useLang";
const store = useStore();
const router = useRouter();
const showLogin = ref(false);
const { isLogin } = useUser();
const vm = getCurrentInstance();
const { langList, getLangObject, setLan } = useLang();
// 切换语言
const changeLang = (item) => {
  setLan(item);
  router.go(0)
};
const jumpPage = (url) => {
  router.push(url);
};
// 个人菜单
const changeMenu = async (item) => {
  // 退出登录
  if (item == 5) {
    store.dispatch("user/logOut");
    Message({
      message: vm.proxy.$t("tips.loginoutSuccess"),
      type: "success",
    });
    router.push("/login");
  } else if (item == 1) {
    router.push("/innerPage/memberCenter");
  } else if (item == 4) {
    router.push("/investmentStatistics");
  } else if (item == 3) {
    router.push("/bankWallet");
  }
};
// 个人中心
const changeUserMenu = (item) => {
  console.log(item)
  if (item == 1) {
    router.push("/innerPage/heloCenter?id=35");
  } else if (item == 2) {
    router.push("/innerPage/heloCenter?id=19");
  }
};
</script>
<style lang="scss" scoped>
.headerTopRight {
  .statusItem {
    box-sizing: border-box;
    padding: 10px;
    cursor: pointer;
    &:hover {
      background-color: $hover-color;
    }
    span {
      font-size: 16px;
    }
  }
}
</style>
