import Cookies from "js-cookie";
const TokenKey = "pro-token";
const CookieKey = "hzAdmin-1.5.9-token";

export function getToken() {
  return localStorage.getItem(TokenKey);
}

export function setToken(token) {
  Cookies.set(CookieKey, token);
  return localStorage.setItem(TokenKey, token);
}

export function removeToken() {
  Cookies.remove(CookieKey);
  return localStorage.removeItem(TokenKey);
}

export function removeAllCookie() {
  //清除所有cookie函数
  var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
  if (keys) {
    for (var i = keys.length; i--; )
      document.cookie = keys[i] + "=0;expires=" + new Date(0).toUTCString();
  }
}
