export default {
  loginInfo: {
    loginTitle: "帳號登入",
    registerTitle: "帳號註冊",
    loginTip: "歡迎回來！使用您的電子郵箱或手機號登入",
    registerTip: "使用您的電子郵箱或手機號進行註冊",
    userNamePlc: "請輸入帳號",
    userPassPlc: "請輸入密碼",
    login: "登入",
    register: "註冊",
    overtime: "登錄超時，請重新登錄",
    loginSuccess: "登入成功",
    registerSuccess: "註冊成功",
    userNameTip: "請輸入帳號",
    userPassTip: "請輸入密碼",
    userOldPassTip: "請輸入舊密碼",
    userNewPassTip: "請輸入新密碼",
    userPassAgainTip: "請再次輸入新密碼",
    errorPassTip: "兩次密碼不一致",
    userPassSureTip: "請再次輸入密碼",
    jumpPageText: "當前頁面需要登錄查看，請登錄",
  },
  tips: {
    loginoutSuccess: "退出登錄成功",
    tip: "提示",
    success: "成功",
    error: "失敗",
    pleaseSelect: "請選擇",
    pleaseEnter: "請輸入",
    pleaseUpload: "請上傳",
    cancelText: "取消",
    sureText: "確定",
    submitText: "提交",
    uploadImg: "請上傳正確的圖片格式！",
    uploadImgSize: "請上傳小於5M的圖片！",
    inputCode: "輸入驗證碼",
    inputNewEmail: "輸入新郵箱",
    inputSureEmail: "輸入正確格式郵箱",
    getCodeText: "獲取驗證碼",
    editText: "修改",
    viewText: "查看",
    reGetCodeText: "後重新獲取",
    editPassTip: "修改登錄密碼",
    editPayTip: "修改支付密碼",
    loadingText: "加載中",
    addText: "新增",
    sureRelieveText: "確認解綁嗎？",
    sureClosing: "確認平倉嗎？",
    pleaseRechargeChoose: "請選擇充值通道",
    pleaseRechargeInput: "請輸入充值金額",
  },
  userText: {
    primaryCertification: "初級認證",
    advancedCertification: "高級認證",
    hasAuth: "已認證",
    noAuth: "未認證",
    auditIng: "審核中",
    userInfo: "個人資訊",
    idCardInfo: "身份證信息",
    cardInfo: "證件信息",
    startBegin: "立即開始",
    authTip: "初級認證後可進行高級認證",
    userName: "名字",
    sureName: "姓氏",
    cardNumber: "證件編號",
    cardType: "證件類型",
    cityName: "國家地區",
    addressName: "地址",
    idCardType: "身份證",
    passport: "護照",
    drivingLicense: "駕駛執照",
    idCardFront: "身份證正面",
    idCardBack: "身份證反面",
    payPassText: "支付密碼",
    loginPassText: "登錄密碼",
    bindEmailText: "綁定郵箱",
    dataStatistics: "數據統計",
    transactionVarieties: "歷史交易品種",
    accuracy: "準確率",
    currentPosition: "當前持倉",
    totalOrder: "總訂單",
    profitOrder: "盈利訂單",
    lossOrder: "虧損訂單",
    successOrder: "成功做多訂單",
    successShortOrder: "成功做空訂單",
    yesterdayTransaction: "昨日交易狀況",
    profitText: "收益",
    historyFollow: "歷史跟隨人數",
    fictitiousContractName: "虛擬幣秒合約",
    orderRatio: "訂單占比",
    buyNumber: "買入單數",
    sellNumber: "賣出單數",
    metalsSecondContract: "外匯/貴金屬秒合約",
    virtualCoinContract: "虛擬幣合約",
    preciousMetalContracts: "外匯/貴金屬合約",
    bindDigitalAddress: "綁定數字地址",
    digitalAddress: "數字地址",
    bindText: "綁定",
    relieveText: "解除",
    addBankText: "添加數字錢包",
    bankAddress: "錢包地址",
    rechargeCurrency: "充值幣種",
    bankRecharge: "儲蓄卡充值",
    manualService: "人工充值服務",
  },
  // 顶部导航
  headerText: {
    recharge: "快速充值",
    // 个人中心
    memberCenter: "會員中心",
    switchAccounts: "切換帳號",
    bankCard: "銀行卡包",
    investmentStatistics: "投資統計",
    logOut: "退出登錄",
    // 右上角菜单
    helpCenter: "幫助中心",
    aboutSelf: "關於我們",
    startBegin: "新手入門",
    // 扫码提示
    scanText: "請掃描此二維碼",
    // 下载提示
    downText: "下載",
    liaison: "聯絡",
    customerService: "客服",
    login: "登錄/註冊",
    accountSet: "帳戶設置",
  },
  // 菜单
  menu: {
    quotation: "行情",
    transactionType: "交易種類",
    transaction: "交易",
    currencytransaction: "幣幣交易",
    fiatTransaction: "法幣交易",
    contract: "合約",
    assets: "資產",
    secondContract: "秒合約",
  },
  // 榜单
  rankText: {
    priceIncrease: "漲幅榜",
    transactionAmount: "成交額榜",
    name: "名稱",
    nowPrice: "最新價",
    transactionType: "交易品種",
    amount: "量",
    operation: "操作",
    futures: "期貨",
    priceRate: "漲跌幅",
    emptyText: "暫無數據",
  },
  // 首页底部
  homeFooter: {
    joinUs: "歡迎加入NYSEIMF，讓我們一起共建NYSEIMF藍圖 共同建造NYSEIMF輝煌",
  },
  // 行情
  market: {
    typeName: "類型",
    digitalCurrency: "數字貨幣",
    nobleMetal: "貴金屬",
    internationalFutures: "國際期貨",
    currency: "幣種",
    foreignExchange: "外匯",
    marketText: "市場",
    productName: "產品",
    dealPrice: "成交價",
    direction: "方向",
    time: "時間",
    profitLoss: "盈虧",
    beginPrice: "開盤",
    highestPrice: "最高",
    lowestPrice: "最低",
    turnover: "成交量",
    closePrice: "封盤",
    buyMore: "買多",
    buyEmpty: "買空",
    doMore: "做多",
    doLess: "做空",
    hasNum: "持倉",
    tip: "選擇到期時間(左滑更高收益)",
    second: "秒",
    numberInput: "投資金額",
    numberInputPlac: "請輸入投資金額",
    canUse: "可用",
    balance: "餘額",
    serviceCharge: "手續費",
    commission: "市價委託",
    commissionText: "市價",
    fixedPrice: "限價委託",
    fixedText: "限價",
    commissionPlac: "請輸入委託金額",
    area: "範圍",
    price: "價格",
    volume: "數量",
    volumePlac: "請輸入數量",
    coinCurrency: "幣幣",
    currentCommission: "當前委託",
    historyCommission: "歷史委託",
    introduction: "簡介",
    wholeAll: "全部",
    transactionRecords: "交易記錄",
    tradingPairs: "交易對",
    aTurnover: "交易額",
    newPrice: "最新價格",
    orderNum: "訂單號",
    status: "狀態",
    entrustmentTime: "委託時間",
    openHouse: "開倉",
    openHousePrice: "開倉價",
    nowText: "當前",
    netWorth: "淨值",
    riskLevel: "風險度",
    takeProfit: "止盈",
    profitPlace: "請輸入止盈金額",
    stopLoss: "止損",
    lossPlace: "請輸入止損金額",
    bondMoney: "保證金",
    fontMoney: "每手價格",
    font: "手",
    buyMoreBtn: "買入做多（看漲）",
    buyLessBtn: "賣出做空（看跌）",
    recharge: "充值",
    rechargeTips: "充值明細",
    withdraw: "提現",
    floatingProfit: "浮動盈虧(實盤)",
    accountBalance: "帳號餘額",
    accountChoose: "選擇帳號",
    accountChoosePlac: "請選擇帳號",
    freeMargin: "可用保證金",
    usedMargin: "已用保證金",
    accountNet: "帳戶淨值",
    freezeAmount: "凍結金額",
    coinAccount: "幣幣帳戶",
    contractAccount: "合約帳戶",
    freezeText: "凍結",
    disassemble: "折合(USD)",
    transactionPrice: "交易價格",
    openingNum: "開倉數量",
    guaranteePrice: "擔保資產",
    nowPrice: "當前價",
    takeProfitPrice: "止盈價",
    stopLossPrice: "止損價",
    hangingPrice: "掛單價",
    historyOrders: "交易歷史訂單",
    typeCurrency: "類型/幣種",
    buyMoney: "買入金額",
    sellPrice: "賣出價格",
    buyPrice: "購買價",
    profit: "盈利",
    buyTime: "買入時間",
    finshTime: "完成時間",
    sellTime: "賣出時間",
    chainName: "鏈名稱",
    chainChoosePlac: "選擇幣種",
    chainNumPlac: "請輸入數量",
    xxpectedRechargeTitle: "預計充值 USDT 數額",
    uploadImgText: "上傳圖片",
    markPaid: "標記已支付",
    payMoney: "支付金額",
    payTime: "支付時間",
    payStatus: "支付狀態",
    withdrawalDetails: "提現明細",
    remark: "備註",
    rechargeAmount: "充值金額",
    withdrawalAmount: "提現金額",
    withdrawalAmountPlac: "請輸入提現金額",
    withdrawalTips: "最低提現金額為",
    nowNumberError: "當前提現金額小於最小提現金額",
    submitBtnText: "確認提交",
    orderNumber: "下單數量",
  },
  order: {
    buyIng: "交易中",
    closing: "平倉中",
    closingText: "平倉",
    hasClosed: "已平倉",
    waitPay: "待付款",
    rechargeFail: "充值失敗",
    rechargeSuccess: "充值成功",
    callbacking: "回調中",
  },
  請先完成實名認證: "請先完成實名認證",
  參數錯誤: "參數錯誤",
  數據未找到: "數據未找到",
  還未創建賬戶: "還未創建賬戶",
  "錢包未找到,請先添加錢包": "錢包未找到,請先添加錢包",
  資金錢包餘額不足: "資金錢包餘額不足",
  入金成功: "入金成功",
  項目不存在: "項目不存在",
  "最少存入數量為：": "最少存入數量為：",
  success: "成功",
  數量不可低於0: "數量不可低於0",
  賬戶餘額不足: "賬戶餘額不足",
  你的凍結餘額為0: "你的凍結餘額為0",
  存幣成功: "存幣成功",
  找不到存單: "找不到存單",
  非法操作: "非法操作",
  存單狀態異常: "存單狀態異常",
  毀約成功: "毀約成功",
  功能暫不可用: "功能暫不可用",
  訂單不能取消: "訂單不能取消",
  提取數量超過持有數量: "提取數量超過持有數量",
  找不到交易對: "找不到交易對",
  帳戶已被凍結: "帳戶已被凍結",
  請求異常: "請求異常",
  狀態異常: "狀態異常",
  "取消失敗:": "取消失敗:",
  取消成功: "取消成功",
  pid不能為空: "pid不能為空",
  產品不存在: "產品不存在",
  配置錯誤: "配置錯誤",
  金額錯誤: "金額錯誤",
  項目狀態錯誤: "項目狀態錯誤",
  暫無數據: "暫無數據",
  文件大小超出: "文件大小超出",
  該文件已存在: "該文件已存在",
  文件類型不對: "文件類型不對",
  上傳失敗: "上傳失敗",
  訂單取消成功: "訂單取消成功",
  配置不存在: "配置不存在",
  您尚未激活此交易對的交易功能: "您尚未激活此交易對的交易功能",
  "您的交易當前正在關閉，此時無法繼續交易":
    "您的交易當前正在關閉，此時無法繼續交易",
  類型錯誤: "類型錯誤",
  此功能當前未打開: "此功能當前未打開",
  限價交易不能以低於當前價格的價格出售: "限價交易不能以低於當前價格的價格出售",
  提交成功: "提交成功",
  此功能系統未打開: "此功能系統未打開",
  止盈止損價格不能為0: "止盈止損價格不能為0",
  找不到交易記錄: "找不到交易記錄",
  "買入（多頭）止盈價格不能低於開盤價和當前價格":
    "買入（多頭）止盈價格不能低於開盤價和當前價格",
  "買入（做多）止損價格不能高於開盤價和當前價格":
    "買入（做多）止損價格不能高於開盤價和當前價格",
  "賣出（賣空）止盈價格不能高於開盤價和當前價格":
    "賣出（賣空）止盈價格不能高於開盤價和當前價格",
  "賣出（賣空）止損價格不能低於開盤價和當前價格":
    "賣出（賣空）止損價格不能低於開盤價和當前價格",
  設置成功: "設置成功",
  設置失敗: "設置失敗",
  購買方向傳輸錯誤: "購買方向傳輸錯誤",
  取消成功: "取消成功",
  "取消失敗：": "取消失敗：",
  "取消失敗：用戶錢包不存在": "取消失敗：用戶錢包不存在",
  "交易記錄不存在或已被取消。請刷新並重試":
    "交易記錄不存在或已被取消。請刷新並重試",
  "找不到錢包，請先添加錢包": "找不到錢包，請先添加錢包",
  "餘額不足，不能小於": "餘額不足，不能小於",
  "扣款失敗：": "扣款失敗：",
  "手續費扣減失敗：": "手續費扣減失敗：",
  未找到數據: "未找到數據",
  未經授權的操作: "未經授權的操作",
  "交易狀態異常，請不要重新提交": "交易狀態異常，請不要重新提交",
  "關閉失敗，請重試": "關閉失敗，請重試",
  "交易異常，無法平倉": "交易異常，無法平倉",
  請輸入賬號: "請輸入賬號",
  請輸入密碼: "請輸入密碼",
  用戶不存在: "用戶不存在",
  密碼錯誤: "密碼錯誤",
  "您的帳戶已被凍結。詳情請諮詢客服。": "您的帳戶已被凍結。詳情請諮詢客服。",
  密碼只能在6到16位之間: "密碼只能在6到16位之間",
  驗證碼錯誤: "驗證碼錯誤",
  用戶名已存在: "用戶名已存在",
  請填寫正確的邀請碼: "請填寫正確的邀請碼",
};
